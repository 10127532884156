import type _Vue from 'vue'
import HagertyAnalytics from './tenant/HagertyAnalytics'
import type IAnalyticsConfig from './IAnalyticsConfig'
import Analytics from './tenant/Analytics'
import KeeyPartnersAnalytics from './tenant/KeeyPartnersAnalytics'
import ZoomcoverAnalytics from './tenant/ZoomcoverAnalytics'
import YogaAnalytics from './tenant/YogaAnalytics'
import type IAnalyticsWindow from './IAnalyticsWindow'
import QuotaxAnalytics from './tenant/QuotaxAnalytics'
import TractiveAnaltics from './tenant/TractiveAnalytics'
import DashAnalytics from './tenant/DashAnalytics'
import TenantEnum from '@/services/enums/TenantEnum'

declare const window: IAnalyticsWindow

export default function AnalyticsPlugin(Vue: typeof _Vue, config: IAnalyticsConfig): void {
  switch (config.tenant) {
    case TenantEnum.Hagerty:

      Vue.prototype.$analytics = new HagertyAnalytics(new Vue(), config, window)
      break
    case TenantEnum.KeeyPartners:

      Vue.prototype.$analytics = new KeeyPartnersAnalytics(new Vue(), config, window)
      break
    case TenantEnum.Quotax:

      Vue.prototype.$analytics = new QuotaxAnalytics(new Vue(), config, window)
      break
    case TenantEnum.Yoga:

      Vue.prototype.$analytics = new YogaAnalytics(new Vue(), config, window)
      break
    case TenantEnum.Zoomcover:

      Vue.prototype.$analytics = new ZoomcoverAnalytics(new Vue(), config, window)
      break
    case TenantEnum.Tractive:

      Vue.prototype.$analytics = new TractiveAnaltics(new Vue(), config, window)
      break
    case TenantEnum.Dash:

      Vue.prototype.$analytics = new DashAnalytics(new Vue(), config, window)
      break
    default:

      Vue.prototype.$analytics = new Analytics(new Vue(), config, window)
  }
}
