enum InputComponentTypeEnum {
  ukDrivingLicence = 'UKDrivingLicenceInput',
  address = 'AddressInput',
  vehicleLookup = 'VehicleLookup',
  autocomplete = 'Autocomplete',
  relatedAutoComplete = 'RelatedAutoComplete',
  additionalPerson = 'AdditionalPerson',
  pets = 'Pets',
  storageLocations = 'StorageLocations',
  number = 'NumberInput',
  markdown = 'Markdown',
  text = 'TextInput',
  date = 'DatePicker',
  select = 'SelectList',
  multiSelect = 'MultiSelect',
  radio = 'RadioList',
  checkbox = 'CheckboxInput',
  declarationCheckbox = 'DeclarationCheckbox',
  optInOptOut = 'OptInOptOutInput',
  verisk = 'VeriskInput',
  claims = 'Claims',
  convictions = 'Convictions',
  medicalconditions = 'MedicalConditions',
  modifications = 'Modifications',
  dateSelect = 'DateSelect',
  textConfirm = 'TextInputConfirm',
  relatedSelectList = 'RelatedSelectList',
  relatedCustomSelectList = 'RelatedCustomSelectList',
  additionalVehicle = 'AdditionalVehicle',
  modal = 'Modal',
  relatedRadioList = 'RelatedRadioList',
  specifiedItems = 'SpecifiedItems',
  multiSelectRadio = 'MultiSelectRadio',
  vehicleRegistrationInput = 'VehicleRegistrationInput',
}

export default InputComponentTypeEnum
