import type { JsonConvert } from 'json2typescript'
import type { AxiosInstance } from 'axios'
import type IRiskService from './interfaces/IRiskService'
import type Risk from './models/Risk'
import { CarRisk, CaravanRisk, GapRisk, PetRisk, PropertyRisk, TouringCaravanRisk,
} from './models/Risk'
import type IResponseMapper from './interfaces/IResponseMapper'
import type PageAnswers from './values/PageAnswers'
import type Account from './models/Account'
import ProductEnum from './enums/ProductEnum'
import VehicleRisk from './models/Vehicle/VehicleRisk'
import DynamicRisk from './models/Dynamic/DynamicRisk'
import type { Dictionary } from '@/types'
import type Page from '@/view-models/Page'

export default class RiskService implements IRiskService {
  axios: AxiosInstance

  apiUrl: URL

  jsonConvert: JsonConvert

  responseMapper: IResponseMapper

  constructor(axios: AxiosInstance, apiUrl: URL, jsonConvert: JsonConvert, responseMapper: IResponseMapper) {
    this.axios = axios
    this.apiUrl = apiUrl
    this.jsonConvert = jsonConvert
    this.responseMapper = responseMapper
  }

  getByPolicyNumber(policyNumber: string, product: ProductEnum): Promise<Risk> {
    switch (product) {
      case ProductEnum.modernCar:
      case ProductEnum.motorCaravan:
      case ProductEnum.taxi:
      case ProductEnum.courier:
      case ProductEnum.commercialVehicle:
        return this.getByCarPolicyNumber(policyNumber)
      case ProductEnum.gapInsurance:
        return this.getByGapPolicyNumber(policyNumber)
      case ProductEnum.touringCaravan:
        return this.getByTouringCaravanPolicyNumber(policyNumber)
      case ProductEnum.staticCaravanResidential:
        return this.getByCaravanPolicyNumber(policyNumber)
      case ProductEnum.vehicle:
        return this.getByVehiclePolicyNumber(policyNumber)
      case ProductEnum.multiPet:
        return this.getByPetPolicyNumber(policyNumber)
      case ProductEnum.home:
        return this.getByPropertyPolicyNumber(policyNumber)
      default:
        throw new Error(`Unknown product (${product}).`)
    }
  }

  async getByQuoteReference(quoteReference: string, product: ProductEnum): Promise<Risk> {
    let risk
    switch (product as ProductEnum) {
      case ProductEnum.motorCaravan:
      case ProductEnum.modernCar:
      case ProductEnum.taxi:
      case ProductEnum.courier:
      case ProductEnum.commercialVehicle:
        risk = await this.getByCarQuoteReference(quoteReference)
        break
      case ProductEnum.multiPet:
        risk = await this.getByPetQuoteReference(quoteReference)
        break
      case ProductEnum.touringCaravan:
        risk = await this.getByTouringCaravanQuoteReference(quoteReference)
        break
      case ProductEnum.caravan:
        risk = await this.getByCaravanQuoteReference(quoteReference)
        break
      case ProductEnum.staticCaravanResidential:
        risk = await this.getByCaravanQuoteReference(quoteReference)
        break
      case ProductEnum.vehicle:
        risk = await this.getByVehicleQuoteReference(quoteReference)
        break
      case ProductEnum.gapInsurance:
        risk = await this.getByGapQuoteReference(quoteReference)
        break
      case ProductEnum.home:
        risk = await this.getByPropertyQuoteReference(quoteReference)
        break
      default:
        throw new Error(`Unknown product (${product}).`)
    }
    return risk
  }

  async getByCarQuoteReference(quoteReference: string): Promise<CarRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/quote/${encodeURIComponent(quoteReference)}`)
    return this.jsonConvert.deserializeObject(result.data, CarRisk)
  }

  async getByCarPolicyNumber(policyNumber: string): Promise<CarRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/policy/${encodeURIComponent(policyNumber)}`)
    return this.jsonConvert.deserializeObject(result.data, CarRisk)
  }

  async getByPetQuoteReference(quoteReference: string): Promise<PetRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/quote/${encodeURIComponent(quoteReference)}`)
    return this.jsonConvert.deserializeObject(result.data, PetRisk)
  }

  async getByGapQuoteReference(quoteReference: string): Promise<GapRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/quote/${encodeURIComponent(quoteReference)}`)
    return this.jsonConvert.deserializeObject(result.data, GapRisk)
  }

  async getByTouringCaravanQuoteReference(quoteReference: string): Promise<TouringCaravanRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/quote/${encodeURIComponent(quoteReference)}`)
    return this.jsonConvert.deserializeObject(result.data, TouringCaravanRisk)
  }

  async getByCaravanQuoteReference(quoteReference: string): Promise<CaravanRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/quote/${encodeURIComponent(quoteReference)}`)
    return this.jsonConvert.deserializeObject(result.data, CaravanRisk)
  }

  async getByTouringCaravanPolicyNumber(policyNumber: string): Promise<TouringCaravanRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/policy/${encodeURIComponent(policyNumber)}`)
    return this.jsonConvert.deserializeObject(result.data, TouringCaravanRisk)
  }

  async getByCaravanPolicyNumber(policyNumber: string): Promise<CaravanRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/policy/${encodeURIComponent(policyNumber)}`)
    return this.jsonConvert.deserializeObject(result.data, CaravanRisk)
  }

  async getByGapPolicyNumber(policyNumber: string): Promise<GapRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/policy/${encodeURIComponent(policyNumber)}`)
    return this.jsonConvert.deserializeObject(result.data, GapRisk)
  }

  async getByPetPolicyNumber(policyNumber: string): Promise<PetRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/policy/${encodeURIComponent(policyNumber)}`)
    return this.jsonConvert.deserializeObject(result.data, PetRisk)
  }

  async getByVehicleQuoteReference(quoteReference: string): Promise<VehicleRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/quote/${encodeURIComponent(quoteReference)}`)
    return this.jsonConvert.deserializeObject(result.data, VehicleRisk)
  }

  async getByVehiclePolicyNumber(policyNumber: string): Promise<VehicleRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/policy/${encodeURIComponent(policyNumber)}`)
    return this.jsonConvert.deserializeObject(result.data, VehicleRisk)
  }

  async getByDynamicQuoteReference(quoteReference: string): Promise<DynamicRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/quote/${encodeURIComponent(quoteReference)}`)
    return this.jsonConvert.deserializeObject(result.data, DynamicRisk)
  }

  async getByDynamicPolicyNumber(policyNumber: string): Promise<DynamicRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/policy/${encodeURIComponent(policyNumber)}`)
    return this.jsonConvert.deserializeObject(result.data, DynamicRisk)
  }

  async getByPropertyQuoteReference(quoteReference: string): Promise<PropertyRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/quote/${encodeURIComponent(quoteReference)}`)
    return this.jsonConvert.deserializeObject(result.data, PropertyRisk)
  }

  async getByPropertyPolicyNumber(policyNumber: string): Promise<PropertyRisk> {
    const result = await this.axios.get(`${this.apiUrl}/risk/policy/${encodeURIComponent(policyNumber)}`)
    return this.jsonConvert.deserializeObject(result.data, PropertyRisk)
  }

  mapRiskToAnswers(risk: Risk, account: Account, pages: Page[]): Dictionary<PageAnswers> {
    return this.responseMapper.mapRiskToAnswers(risk, account, pages)
  }
}
