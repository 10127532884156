import Hotjar from '@hotjar/browser'
import SmartlookClient from 'smartlook-client'
import _Vue from 'vue'
import VueGtag from 'vue-gtag'
import type IAnalyticsConfig from '@/plugins/analytics/IAnalyticsConfig'

export function loadGoogleTagManager(gtmId: string): void {
  if (!gtmId)
    return
  (function loadGTM(w: any, d: any, s: any, l: any, i: any) {
    w[l] = w[l] || []
    w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
    const f = d.getElementsByTagName(s)[0]
    const j = d.createElement(s)
    const dl = l !== 'dataLayer' ? `&l=${l}` : ''
    j.async = true
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
    f.parentNode.insertBefore(j, f)
  })(window, document, 'script', 'dataLayer', gtmId)
}

export function loadHotjar(hotjarId: string): void {
  if (!hotjarId)
    return

  Hotjar.init(Number(hotjarId), 6)
}

export function loadAdobeAnalytics(adobeId: string): void {
  if (!adobeId)
    return

  const a = adobeId
  const b = document
  const c = 'script'
  const d = b.createElement(c) as HTMLScriptElement
  d.src = a
  d.type = `text/java${c}`
  d.async = true
  const e = b.getElementsByTagName(c)[0]
  e.parentNode!.insertBefore(d, e)
}

export function loadSmartlookClient(smartlookId: string): void {
  if (!smartlookId)
    return

  SmartlookClient.init(smartlookId)
}

export function loadMicrosoftAdvertising(microsoftAdvertisingId: string): void {
  if (microsoftAdvertisingId) {
    (
      /* eslint-disable no-unused-expressions, new-cap, no-sequences, style/no-mixed-operators */
      function (w: any, d: any, t: any, r: any, u: any) {
        let f: any, n: any, i: any
        w[u] = w[u] || [], f = function () {
          const o = { ti: microsoftAdvertisingId } as any
          o.q = w[u], w[u] = new (o)(), w[u].push('pageLoad')
        }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {
          const s = this.readyState
          s && s !== 'loaded' && s !== 'complete' || (f(), n.onload = n.onreadystatechange = null)
        }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)
      })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq')
    /* eslint-enable no-unused-expressions, new-cap, no-sequences, style/no-mixed-operators */
  }
}

export function loadGoogleAnalytics(config: IAnalyticsConfig) {
  _Vue.use(VueGtag, {
    config: {
      id: config.analytics,
      params: {
        send_page_view: false,
      },
    },
  }, config.router)
}
