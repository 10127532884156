import type IAnalyticsConfig from '../IAnalyticsConfig'
import Analytics from './Analytics'
import type AggregatorClient from '@/services/models/AggregatorClient'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type Risk from '@/services/models/Risk'
import { PetRisk } from '@/services/models/Risk'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'
import useAggregatorStore from '@/stores/aggregator-store'
import { loadGoogleAnalytics, loadGoogleTagManager, loadHotjar } from '@/composables/analytics-utils'

export default class KeeyPartnersAnalytics extends Analytics {
  loadAnalytics(config: IAnalyticsConfig): void {
    // Google Tag Manager
    loadGoogleTagManager(window.injectedEnv.FRONTEND_GTM)

    // Google Analytics
    loadGoogleAnalytics(config)

    // Hotjar Tracking
    loadHotjar(window.injectedEnv.FRONTEND_HOTJAR)
  }

  trackNBSale<T extends SchemeQuoteResult>(vue: Vue, policyNumber: string, quote: QuoteDetails<T>, selectedSchemeQuoteResult: number, aggregatorClient: AggregatorClient | null, risk: Risk | null): void {
    const result = quote.results.find(r => r.id === selectedSchemeQuoteResult)!

    // Google Tag Manager
    this.window.dataLayer?.push({
      policyNumber,
      schemeName: result.schemeName,
      premium: result.calculatedTotalPayable.toString(10),
      additional: result.additional.toString(10),
      aggregator: aggregatorClient?.name ?? '',
      event: 'nb-sale',
      policyType: risk instanceof PetRisk ? (risk?.pets.length > 1 ? 'multipet' : 'single') : '',
    })

    if (aggregatorClient?.name.includes('Confused') || aggregatorClient?.name.includes('Compare Cover')) {
      const aggregatorStore = useAggregatorStore()
      const ref = aggregatorStore.queryString.ref
      if (window.location.origin === 'https://community.scratchandpatch.co.uk') {
        try {
          const img = document.createElement('img')
          img.src = `https://www.quote-link.net/petcover/salestracking/petquotes_sale_confirmation.cfm?ref=${ref}&fullprem=${result.calculatedTotalPayable.toString(10)}`
          img.width = 1
          img.height = 1
          document.body.appendChild(img)
          // eslint-disable-next-line no-console
          console.log(`SNP EVENT FIRED --- ${aggregatorClient?.name} agg takeup event sent. ${ref ? `Ref: ${ref}` : null}`)
        }
        catch (error) {
          console.error('Unable to send agg takeup event', error)
        }
      }
      else {
        // eslint-disable-next-line no-console
        console.log(`DUMMY SNP EVENT FIRED --- ${aggregatorClient?.name} agg takeup event sent. ${ref ? `Ref: ${ref}` : null}`)
      }
    }

    // Direct to Google Analytics sale tracking
    vue.$gtag?.purchase({

      transaction_id: policyNumber,
      affiliation: 'Ignite CF',
      value: result.calculatedTotalPayable,
      items: [
        {
          id: result.scheme.code,
          name: result.schemeName,
        },
      ],
    })
  }
}
