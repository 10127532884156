enum GlobalEventsEnum {
  setPage = 'set-page',
  setLoadingDisplayText = 'set-loading-text',
  nextClick = 'next-click',
  previousClick = 'previous-click',
  failedValidation = 'failed-validation',
  passedValidation = 'passed-validation',
  checkValidation = 'check-validation',
  expandInvalidSubpage = 'expand-invalid-subpage',
  modalPreviousClicked = 'modalPreviousClicked',
  manageDirectDebit = 'manage-direct-debit',
  manageGoCardlessDetais = 'manage-gocardless-details',
}

export default GlobalEventsEnum
