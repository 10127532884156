import type IAnalyticsConfig from '../IAnalyticsConfig'
import Analytics from './Analytics'
import { loadGoogleAnalytics, loadGoogleTagManager, loadHotjar } from '@/composables/analytics-utils'

export default class DashAnalytics extends Analytics {
  loadAnalytics(config: IAnalyticsConfig): void {
    // Google Tag Manager
    loadGoogleTagManager(window.injectedEnv.FRONTEND_GTM)

    // Google Analytics
    loadGoogleAnalytics(config)

    // Hotjar Tracking
    loadHotjar(window.injectedEnv.FRONTEND_HOTJAR)

    // Hotjar Tracking 2
    loadHotjar(window.injectedEnv.FRONTEND_HOTJAR_2)
  }
}
