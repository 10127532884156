import type IAnalyticsConfig from '../IAnalyticsConfig'
import Analytics from './Analytics'
import type AggregatorClient from '@/services/models/AggregatorClient'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type Risk from '@/services/models/Risk'
import { PetRisk } from '@/services/models/Risk'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'
import { loadGoogleAnalytics, loadGoogleTagManager, loadHotjar } from '@/composables/analytics-utils'

export default class TractiveAnaltics extends Analytics {
  loadAnalytics(config: IAnalyticsConfig): void {
    // Hotjar Tracking
    loadHotjar(window.injectedEnv.FRONTEND_HOTJAR)

    // Google Tag Manager
    loadGoogleTagManager(window.injectedEnv.FRONTEND_GTM)

    // Google Analytics
    loadGoogleAnalytics(config)
  }

  trackNBSale<T extends SchemeQuoteResult>(vue: Vue, policyNumber: string, quote: QuoteDetails<T>, selectedSchemeQuoteResult: number, aggregatorClient: AggregatorClient | null, risk: Risk | null): void {
    const result = quote.results.find(r => r.id === selectedSchemeQuoteResult)!

    // Google Tag Manager
    this.window.dataLayer?.push({
      policyNumber,
      schemeName: result.schemeName,
      premium: result.calculatedTotalPayable.toString(10),
      additional: result.additional.toString(10),
      aggregator: aggregatorClient?.name ?? '',
      event: 'nb-sale',
      policyType: risk instanceof PetRisk ? (risk?.pets.length > 1 ? 'multipet' : 'single') : '',
    })

    // Direct to Google Analytics sale tracking
    vue.$gtag?.purchase({

      transaction_id: policyNumber,
      affiliation: 'Ignite CF',
      value: result.calculatedTotalPayable,
      items: [
        {
          id: result.scheme.code,
          name: result.schemeName,
        },
      ],
    })
  }
}
