import type { Route } from 'vue-router'
import { getActivePinia } from 'pinia'
import Analytics from './Analytics'
import type AggregatorClient from '@/services/models/AggregatorClient'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type Risk from '@/services/models/Risk'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'
import useAccountStore from '@/stores/account-store'
import Routes from '@/constants/Routes'
import { loadAdobeAnalytics, loadGoogleTagManager, loadHotjar } from '@/composables/analytics-utils'

export default class HagertyAnalytics extends Analytics {
  loadAnalytics(): void {
    // Adobe Analytics
    loadAdobeAnalytics(window.injectedEnv.FRONTEND_ADOBE)

    // Hotjar Tracking
    loadHotjar(window.injectedEnv.FRONTEND_HOTJAR)

    // Google Tag Manager
    loadGoogleTagManager(window.injectedEnv.FRONTEND_GTM)
  }

  async trackRouteChange(vue: Vue, to: Route, _from: Route): Promise<void> {
    interface HagertyView {
      _tag_name?: string
      logged_in: boolean
      hashed_email?: string
    }

    const view: HagertyView = {
      logged_in: false,
    }

    if (getActivePinia()) {
      const accountStore = useAccountStore()
      if (accountStore.account) {
        view.logged_in = true

        const encoder = new TextEncoder()
        const data = encoder.encode(accountStore.account.email.toLowerCase())
        const hashBuffer = await crypto.subtle.digest('SHA-256', data)
        const hashArray = Array.from(new Uint8Array(hashBuffer))
        const hashHex = hashArray
          .map(b => b.toString(16).padStart(2, '0'))
          .join('')
        view.hashed_email = hashHex
      }
    }

    if (to.name === Routes.preQuoteAssumptions)
      view._tag_name = 'quote_start'

    else if (to.name === Routes.premium || to.name === Routes.quoteReferred)
      view._tag_name = 'quote_complete'

    else if (to.name === Routes.paymentConfirm)
      view._tag_name = 'application_complete'

    // Adobe Analytics
    this.window.utag?.view(view)
  }

  trackNBSale<T extends SchemeQuoteResult>(_vue: Vue, policyNumber: string, quote: QuoteDetails<T>, selectedSchemeQuoteResult: number, aggregatorClient: AggregatorClient | null, _risk: Risk | null): void {
    const result = quote.results.find(r => r.id === selectedSchemeQuoteResult)!

    // Google Tag Manager
    this.window.dataLayer?.push({
      policyNumber,
      schemeName: result.schemeName,
      premium: result.calculatedTotalPayable.toString(10),
      additional: result.additional.toString(10),
      aggregator: aggregatorClient?.name ?? '',
      event: 'nb-sale',
      policyType: null,
    })
  }
}
