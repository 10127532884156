enum QuestionEnum {
  accidentClaimType = 'AccidentClaimType',
  accompaniedAtAllTimes = 'accompaniedAtAllTimes',
  alarmProfessionallyInstalledMaintained = 'AlarmProfessionallyInstalledMaintained',
  adaptedForWheelchair = 'AdaptedForWheelchair',
  additionalPolicyholders = 'AdditionalPolicyholders',
  additionalDriver = 'AdditionalDriver',
  additionalDrivers = 'AdditionalDrivers',
  additionalVehicle = 'AdditionalVehicle',
  address = 'address',
  addressIsStated = 'addressIsStated',
  advancedDrivingLicenceType = 'AdvancedDrivingLicenseType',
  affiliateMarkdown = 'affiliate-markdown',
  alarmFittedBy = 'AlarmFittedBy',
  alarmFitted = 'AlarmFitted',
  alarmModel = 'AlarmModel',
  alarmType = 'CarAlarmType',
  allSeatsForwardFacing = 'AllSeatsForwardFacing',
  allSeatsHaveSeatbelts = 'AllSeatsHaveSeatbelts',
  amountFinanced = 'AmountFinanced',
  anchoringLock = 'AnchoringLock',
  anyUnspecifiedItems = 'AnyUnspecifiedItems',
  approximateBuildYear = 'ApproximateBuildYear',
  autoRenew = 'AutoRenew',
  autoRenewalOptOut = 'AutoRenewalOptOut',
  awningsPrice = 'AwningsPrice',
  basisOfCover = 'BasisOfCover',
  beenRequestedExtraPrecautions = 'BeenRequestedExtraPrecautions',
  billingPeriod = 'BillingPeriod',
  buildingsContentsOrBoth = 'BuildingsContentsOrBoth',
  firstname = 'Firstname',
  hasPreviousStormDamage = 'HasPreviousStormDamage',
  isPermanentResidence = 'IsPermanentResidence',
  isStaticCaravanAnchored = 'IsStaticCaravanAnchored',
  numberOfCaravanPitches = 'NumberOfCaravanPitches',
  propertyHasBeenFlooded = 'PropertyHasBeenFlooded',
  relationshipToPolicyHolder = 'RelationshipToPolicyHolder',
  staticCaravanCondition = 'StaticCaravanCondition',
  staticCaravanDimensionMeasuredInFeet = 'StaticCaravanDimensionMeasuredInFeet',
  staticCaravanHeatingSystem = 'StaticCaravanHeatingSystem',
  staticCaravanLength = 'StaticCaravanLength',
  staticCaravanNoOfBedroomsDLD = 'StaticCaravanNoOfBedroomsDLD',
  staticCaravanFittedWithFloatationDevice = 'StaticCaravanFittedWithFloatationDevice',
  staticCaravanType = 'StaticCaravanType',
  staticCaravanWidth = 'StaticCaravanWidth',
  storagePostcode = 'StoragePostcode',
  billingAddress = 'billingAddress',
  businessCategory = 'BusinessCategory',
  cancellationReason = 'CancellationReason',
  caravanMakes = 'CaravanMakes',
  caravanModels = 'CaravanModels',
  staticCaravanMake = 'StaticCaravanMake',
  staticCaravanModel = 'StaticCaravanModel',
  caravanSafetyDevices = 'CaravanSafetyDevices',
  caravanSecurity = 'CaravanSecurity',
  caravansecurityTracker = 'CaravansecurityTracker',
  caravanTaggingIdentification = 'CaravanTaggingIdentification',
  caravanType = 'CaravanType',
  caravanUsage = 'CaravanUsage',
  carriesDangerousGoods = 'CarriesDangerousGoods',
  catBreed = 'CatBreed',
  dominantCatBreed = 'DominantCatBreed',
  catType = 'CatType',
  claimCost = 'ClaimCost',
  claimDate = 'ClaimDate',
  claimFault = 'ClaimFault',
  claimFreeYears = 'ClaimFreeYears',
  claimIncidentType = 'ClaimIncidentType',
  claimPersonsInjured = 'ClaimPersonsInjured',
  claims = 'Claims',
  claimsFreeExperienceOnAnotherVehicle = 'ClaimsFreeExperienceOnAnotherVehicle',
  claimStatus = 'ClaimStatus',
  claimType = 'ClaimType',
  classOfUse = 'ClassOfUse',
  clubName = 'ClubName',
  coinsuranceVoluntaryDiscount = 'CoinsuranceVoluntaryDiscount',
  commercialVehicleBodyType = 'CommercialVehicleBodyType',
  commercialVehicleClassOfUse = 'CommercialVehicleClassOfUse',
  companyName = 'CompanyName',
  confirmCommercialType = 'ConfirmCommercialType',
  confirmFullyComp = 'ConfirmFullyComp',
  confirmGreyImport = 'ConfirmGreyImport',
  confirmRegisteredKeeper = 'ConfirmRegisteredKeeper',
  confirmText = 'ConfirmText',
  confirmVehicleUse = 'ConfirmVehicleUse',
  contentsPrice = 'ContentsPrice',
  conversionCompleted = 'ConversionCompleted',
  convictionDate = 'ConvictionDate',
  convictionDisqualificationMonths = 'ConvictionDisqualificationMonths',
  convictionFine = 'ConvictionFine',
  convictionPersonsInjured = 'ConvictionPersonsInjured',
  convictionPointsReceived = 'ConvictionPointsReceived',
  convictions = 'Convictions',
  convictionType = 'ConvictionType',
  convictionIsPending = 'ConvictionIsPending',
  costOfPet = 'CostOfPet',
  coverClass = 'coverClass',
  coverDuration = 'CoverDuration',
  relatedCoverDuration = 'RelatedCoverDuration',
  coverDurationType = 'CoverDurationType',
  coverEffectiveDate = 'CoverEffectiveDate',
  coverForAwnings = 'CoverForAwnings',
  caravanAwningValue = 'CaravanAwningValue',
  coverForContents = 'CoverForContents',
  caravanContentsValue = 'CaravanContentsValue',
  coverForequipment = 'CoverForequipment',
  coverForPersonalEffects = 'CoverForPersonalEffects',
  caravanPersonalEffectsValue = 'CaravanPersonalEffectsValue',
  requireHighRiskItemsInsurance = 'RequireHighRiskItemsInsurance',
  requirePublicLiabilityInsurance = 'RequirePublicLiabilityInsurance',
  publicLiabilityInsuranceValue = 'PublicLiabilityInsuranceValue',
  hotTub = 'HotTub',
  caravanNoClaimsBonus = 'CaravanNoClaimsBonus',
  yearsWithNoClaimsHousehold = 'YearsWithNoClaimsHousehold',
  highRiskItemsValue = 'HighRiskItemsValue',
  costToRebuildProperty = 'CostToRebuildProperty',
  costToReplaceContents = 'CostToReplaceContents',
  coverLevelOrType = 'CoverLevelOrType',
  coverPreExistingConditions = 'CoverPreExistingConditions',
  coverTerm = 'CoverTerm',
  coverType = 'CoverType',
  cubicCapacity = 'CubicCapacity',
  currentInsurer = 'CurrentInsurer',
  currentMileage = 'CurrentMileage',
  customerEmail = 'CustomerEmail',
  customerEmailConfirmation = 'CustomerEmailConfirmation',
  customerHomePhoneNumber = 'CustomerHomePhoneNumber',
  customerVehicleValue = 'CustomerVehicleValue',
  customWarning = 'CustomWarning',
  dateFirstRegistered = 'DateFirstRegistered',
  dateOfBirth = 'DateOfBirth',
  petDateOfBirth = 'PetDateOfBirth',
  dateOfPurchase = 'DateOfPurchase',
  petDateRemoved = 'PetRemovedDate',
  declaration14 = 'Declaration14',
  depositAmount = 'DepositAmount',
  disqualifiedFromDriving = 'DisqualifiedFromDriving',
  dogBreed = 'DogBreed',
  dominantDogBreed = 'DominantDogBreed',
  dogType = 'DogType',
  doYouDriveAnyVehicleBesidesThisOne = 'DoYouDriveAnyVehicleBesidesThisOne',
  driverToVehicles = 'DriverToVehicles',
  drivingBanLength = 'DrivingBanLength',
  drivingFrequency = 'DrivingFrequency',
  drivingLicence = 'drivingLicence',
  drivingOtherCarsOnOtherPolicy = 'isDrivingOtherCars',
  drivingTestPassed = 'DrivingTestPassed',
  dvlaModificationsCarriedOut = 'DVLAModificationsCarriedOut',
  dvlaMotorhome = 'DVLAMotorhome',
  dvlaReportableMedicalConditions = 'DVLAReportableMedicalConditions',
  effectiveDate = 'EffectiveDate',
  email = 'Email',
  employmentStatus = 'EmploymentStatus',
  equipmentPrice = 'EquipmentPrice',
  europeanCover = 'EuropeanCover',
  everSeenVetForIllness = 'EverSeenVetForIllness',
  farePayingPassengers = 'FarePayingPassengers',
  financedOrLeased = 'FinancedOrLeased',
  fineAmount = 'FineAmount',
  fineGiven = 'FineGiven',
  fuelType = 'FuelType',
  gapDamagedBeyondRepair = 'GapDamagedBeyondRepair',
  gapDomesticAndSocial = 'GapDomesticAndSocial',
  gapVehiclePaymentType = 'GapVehiclePaymentType',
  gapVehiclePurchaseType = 'GapVehiclePurchaseType',
  gapVehicleType = 'GapVehicleType',
  vehicleType = 'VehicleType',
  gender = 'Gender',
  goodStateOfRepair = 'GoodStateOfRepair',
  hadInsuranceRefused = 'HadInsuranceRefused',
  hasAccessToAnotherVehicle = 'HasAccessToAnotherVehicle',
  hasAccessToRegularVehicleUse = 'HasAccessToRegularVehicleUse',
  hasAdvancedDrivingLicence = 'HasAdvancedDrivingLicense',
  hasAttacked = 'HasAttacked',
  hasBeenChipped = 'HasBeenChipped',
  hasBeenNeutered = 'HasBeenNeutered',
  hasCaravanSafetyDevices = 'HasCaravanSafetyDevices',
  hasCcjs = 'HasCcjs',
  hasCCTV = 'HasCCTV',
  hasClaims = 'HasClaims',
  hasConvictions = 'HasConvictions',
  hasCriminalConvictions = 'HasCriminalConvictions',
  HasExistingMedicalConditions = 'HasExistingMedicalConditions',
  hasHadPolicyDeclaredVoid = 'HasHadPolicyDeclaredVoid',
  hasHighValueItems = 'HasHighValueItems',
  hasInformedDVLAOfMedicalConditionsAndDisabilities = 'HasInformedDVLAOfMedicalConditionsAndDisabilities',
  hasMedicalConditions = 'HasMedicalConditions',
  hasPerimiterFencing = 'HasPerimiterFencing',
  hasPSVOLicence = 'HasPSVOLicence',
  hasPurchasedProperty = 'HasPurchasedProperty',
  hasRegisteredKeepersPermission = 'HasRegisteredKeepersPermission',
  hasSecondaryEmployment = 'HasSecondaryEmployment',
  hasSecondaryStorageAddress = 'HasSecondaryStorageAddress',
  hasSecurityGate = 'HasSecurityGate',
  homeType = 'HomeType',
  isHavingAdditionalFeatures = 'IsHavingAdditionalFeatures',
  staticCaravanAdditionalSecurity = 'StaticCaravanAdditionalSecurity',
  hasTagging = 'HasTagging',
  hasTracker = 'HasTracker',
  hasTrailer = 'HasTrailer',
  hasUptoDateVaccinations = 'HasUptoDateVaccinations',
  hasWarden = 'HasWarden',
  homeOwner = 'HomeOwner',
  homeStorageLocation = 'HomeStorageLocation',
  homeOwnedOrRented = 'HomeOwnedOrRented',
  howManyAdults = 'HowManyAdults',
  howManyChildren = 'HowManyChildren',
  howLongHaveYouLivedAtTheProperty = 'HowLongAtProperty',
  incidentDate = 'IncidentDate',
  includeCoverForAddedExtra = 'IncludeCoverForAddedExtra',
  includeCoverForVehicleStolen = 'IncludeCoverForVehicleStolen',
  includeAccidentalDamageForBuildings = 'IncludeAccidentalDamageForBuildings',
  includeAccidentalDamageForContents = 'IncludeAccidentalDamageForContents',
  includeSalvageProtection = 'IncludeSalvageProtection',
  insuredItemsCarriedOutsideProperty = 'InsuredItemsCarriedOutsideProperty',
  insuringAMinibus = 'InsuringAMinibus',
  insuringInNameOfCompany = 'InsuringInNameOfCompany',
  isAwaitingReview = 'IsAwaitingReview',
  isBusinessUseOnly = 'isBusinessUseOnly',
  isClubMember = 'IsClubMember',
  isCompanyVehicle = 'IsCompanyVehicle',
  isDriverForUber = 'IsDriverForUber',
  isEmployed = 'isEmployed',
  isImpounded = 'isImpounded',
  isVehicleImpounded = 'IsVehicleImpounded',
  isInsuredAnnually = 'isInsuredAnnually',
  isModified = 'isModified',
  isPermanantlySited = 'IsPermanantlySited',
  isPetDeceased = 'IsPetDeceased',
  isPrivatelyStored = 'IsPrivatelyStored',
  isRegisteredInUK = 'isRegisteredInUK',
  isRegisteredKeeper = 'isRegisteredKeeper2',
  isRegisteredKeeperDeclaration = 'IsRegisteredKeeper',
  isRegisteredKeeperOrHasPermission = 'IsRegisteredKeeperOrHasPermission',
  isRegisteredOwnerAndKeeper = 'IsRegisteredOwnerAndKeeper',
  isUKResident = 'isUKResident',
  isUsedForPrivateHire = 'IsUsedForPrivateHire',
  isVehicleCarOrVan = 'isVehicleCarOrVan',
  isVehicleHireOrRental = 'isVehicleHireOrRental',
  isVehicleModified = 'IsVehicleModified',
  isVehicleRegisteredInUK = 'IsVehicleRegisteredInUK',
  isVehicleRegisteredOutsideUK = 'IsVehicleRegisteredOutsideUK',
  isVehicleRightHandDrive = 'IsVehicleRightHandDrive',
  keepingYouInformed = 'KeepingYouInformed',
  lastName = 'Lastname',
  leftHandDriveReason = 'LeftHandDriveReason',
  licenceDate = 'LicenceDate',
  licenceHeldYears = 'LicenceHeldYears',
  licenceNumber = 'LicenceNumber',
  licenceType = 'LicenceType',
  liveInVehicleFullTime = 'LiveInVehicleFullTime',
  loanWithPermits = 'LoanWithPermits',
  mainLicensingAuthority = 'MainLicensingAuthority',
  mainUser = 'MainUser',
  manoeuvringCourse = 'ManoeuvringCourse',
  manyPetsText = 'ManyPetsText',
  maritalStatus = 'MaritalStatus',
  marketing = 'marketing',
  marketPrice = 'MarketPrice',
  medicalCondition = 'MedicalCondition',
  medicalconditions = 'MedicalConditions',
  mobilePhoneNumber = 'CustomerMobilePhoneNumber',
  modernCarAlarmType = 'ModernCarAlarmType',
  modification = 'Modification',
  modifications = 'Modifications',
  modifiedEngineDriveTrain = 'ModifiedEngineDriveTrain',
  motorhomeType = 'MotorhomeType',
  mtaEffectiveDate = 'MTAEffectiveDate',
  mustProvideDocumentsForClaims = 'mustProvideDocumentsForClaims',
  name = 'Name',
  ncbLost = 'NcbLost',
  needCoverForAdditionalDriver = 'NeedCoverForAdditionalDriver',
  noClaimsBonus = 'NoClaimsBonus',
  noClaimsProtection = 'NoClaimsProtection',
  noCommercialUse = 'noCommercialUse',
  noCriminalConvictions = 'noCriminalConvictions',
  noHazardousUse = 'noHazardousUse',
  noMaxClaims = 'noMaxClaims',
  noPSVOLicenseReason = 'NoPSVOLicenseReason',
  notDisqualified = 'notDisqualified',
  notExceedVehicleMaxValue = 'notExceedVehicleMaxValue',
  numberOfCaravans = 'NumberOfCaravans',
  numberOfDrops = 'NumberOfDrops',
  numberOfHouseholdVehicles = 'NumberOfHouseholdVehicles',
  numberOfMonthsStored = 'NumberOfMonthsStored',
  numberOfOtherVehiclesAvailable = 'NumberOfOtherVehiclesAvailable',
  numberOfTemporaryDays = 'NumberOfTemporaryDays',
  numberOfFaultClaims = 'NumberOfFaultClaims',
  numberOfNonFaultClaims = 'NumberOfNonFaultClaims',
  occupation = 'Occupation',
  operatingInLondonArea = 'OperatingInLondonArea',
  optionalFinalPayment = 'OptionalFinalPayment',
  otherClaimType = 'OtherClaimType',
  otherLicensingAuthorities = 'OtherLicensingAuthorities',
  otherLocation = 'OtherLocation',
  otherNoClaimsBonus = 'OtherNoClaimsBonus',
  otherVehiclesYouDrive = 'OtherVehiclesYouDrive',
  ownerVehicleYears = 'OwnerVehicleYears',
  paymentDue = 'PaymentDue',
  penaltyPointsGiven = 'PenaltyPointsGiven',
  percentageContract = 'PercentageContract',
  personalEffectsPrice = 'PersonalEffectsPrice',
  petCoverType = 'PetCoverType',
  pets = 'Pets',
  petsKeptAtProposerAddress = 'PetsKeptAtProposerAddress',
  petSubCoverType = 'PetSubCoverType',
  petType = 'PetType',
  policyCeasesAfterPassTest = 'policyCeasesAfterPassTest',
  policyClass = 'PolicyClass',
  bikeClassOfUse = 'BikeClassOfUse',
  policyDurationZBUOI = 'policyDurationZBUOI',
  policyDurationZCSI = 'policyDurationZCSI',
  policyDurationZIVI = 'policyDurationZIVI',
  policyDurationZLBCI = 'policyDurationZLBCI',
  policyDurationZLOCI = 'policyDurationZLOCI',
  policyDurationZTI = 'policyDurationZTI',
  policyDurationZTUC = 'policyDurationZTUC',
  policyDurationZVI = 'policyDurationZVI',
  policyEndDate = 'PolicyEndDate',
  policyholderType = 'PolicyholderType',
  postcode = 'postcode',
  preExistingText = 'PreExistingText',
  presentValue = 'PresentValue',
  previousPolicyExpiration = 'PreviousPolicyExpiration',
  primaryTransport = 'PrimaryTransport',
  primaryStorageAddress = 'PrimaryStorageAddress',
  PRN = 'PRN',
  propertyType = 'PropertyType',
  proposerAddress = 'ProposerAddress',
  proposerOwnsPet = 'ProposerOwnsPet',
  psvLicenceDate = 'PSVLicenceDate',
  psvLicenceNumber = 'PSVLicenceNumber',
  pSVNamedProposer = 'PSVNamedProposer',
  pSVOLicenceNumber = 'PSVOLicenceNumber',
  pSVONamedProposerReason = 'PSVONamedProposerReason',
  purchasePrice = 'PurchasePrice',
  replacementValue = 'ReplacementValue',
  replacementCost = 'ReplacementCost',
  requireLeisureEquipmentInsurance = 'RequireLeisureEquipmentInsurance',
  leisureEquipmentValue = 'LeisureEquipmentValue',
  reasonForChange = 'ReasonForChange',
  reasonForCover = 'ReasonForCover',
  referralSource = 'ReferralSource',
  referralSourceGroup = 'ReferralSourceGroup',
  registeredKeeper = 'RegisteredKeeper',
  registeredKeeperAddress = 'RegisteredKeeperAddress',
  registeredKeeperFirstName = 'RegisteredKeeperFirstName',
  registeredKeeperLastName = 'RegisteredKeeperSurName',
  registeredKeeperTelephoneNumber = 'RegisteredKeeperTelephoneNumber',
  registeredOutsideUKReason = 'RegisteredOutsideUKReason',
  registeredWithCRiS = 'RegisteredWithCRiS',
  regularUseVehicleDetail = 'RegularUseVehicleDetail',
  residentSince = 'ResidentSince',
  residentSinceBirth = 'ResidentSinceBirth',
  restrictedLicense = 'RestrictedLicense',
  restrictedLicenseType = 'RestrictedLicenseType',
  riderWillCarryPillion = 'RiderWillCarryPillion',
  rideSharingApp = 'RideSharingApp',
  roadsideProhibition = 'RoadsideProhibition',
  seatbeltDetails = 'SeatbeltDetails',
  seatDetails = 'SeatDetails',
  secondaryBusinessCategory = 'SecondaryBusinessCategory',
  secondaryEmploymentStatus = 'SecondaryEmploymentStatus',
  secondaryOccupation = 'SecondaryOccupation',
  secondaryStorageAddress = 'SecondaryStorageAddress',
  sectionPermit = 'SectionPermit',
  selfLoad = 'SelfLoad',
  servicedEveryTwoYears = 'ServicedEveryTwoYears',
  shortTermCover = 'ShortTermCover',
  sizeOfPet = 'SizeOfPet',
  dominantBreedSizeOfPet = 'DominantBreedSizeOfPet',
  specifiedItemDescription = 'SpecifiedItem',
  specifiedItemsSubpage = 'SpecifiedItems',
  storageLocation = 'StorageLocation',
  storageLocations = 'StorageLocations',
  storedGoodsDetails = 'StoredGoodsDetails',
  taxiBadgeSince = 'TaxiBadgeSince',
  theftClaimType = 'TheftClaimType',
  title = 'Title',
  touringClubName = 'TouringClubName',
  towingFrequency = 'TowingFrequency',
  trackerModel = 'TrackerModel',
  trackerType = 'CarTrackerType',
  trailerDetails = 'TrailerDetails',
  trailerMaxCapacity = 'TrailerMaxCapacity',
  trailerValue = 'TrailerValue',
  trailerVIN = 'TrailerVIN',
  typeOfCaravanPark = 'TypeOfCaravanPark',
  typeOfCompound = 'TypeOfCompound',
  typeOfItem = 'TypeOfItem',
  typeOfFarm = 'TypeOfFarm',
  typeOfGoods = 'TypeOfGoods',
  typeOfVehicle = 'TypeOfVehicle',
  uberBranch = 'UberBranch',
  uberProRating = 'UberProRating',
  ukResident = 'UKResident',
  ukResidentSince = 'UKResidentSince',
  ukUseOnly = 'UKUseOnly',
  unknownMotorhomeType = 'UnknownMotorhomeType',
  unspecifiedItemValue = 'UnspecifiedItemValue',
  unspentConvictions = 'UnspentConvictions',
  usedForBreedingOrConnectedToBusiness = 'UsedForBreedingOrConnectedToBusiness',
  useOfAnotherCar = 'UseOfAnotherCar',
  useOfAnotherVehicle = 'useOfAnotherVehicle',
  userSpecifiedNumberOfDrivers = 'UserSpecifiedNumberOfDrivers',
  vatRegisteredUK = 'VATRegisteredUK',
  vehicleAddress = 'VehicleAddress',
  vehicleAlarmType = 'VehicleAlarmType',
  vehicleBusinessMileage = 'VehicleBusinessMileage',
  vehicleCollectionDate = 'VehicleCollectionDate',
  vehicleCondition = 'VehicleCondition',
  vehicleDeliveryTaken = 'VehicleDeliveryTaken',
  vehicleEstimatedMileage = 'VehicleEstimatedMileage',
  vehicleFuelType = 'vehicleFuelType',
  vehicleImported = 'VehicleImported',
  vehicleIsModified = 'VehicleIsModified',
  vehicleKeeper = 'VehicleKeeper',
  vehicleKeptAtPostCode = 'VehicleKeptAtPostCode',
  vehicleKeptAtHome = 'VehicleKeptAtHome',
  vehicleLookup = 'vehicleLookup',
  vehicleOvernightParkLocation = 'VehicleOvernightParkLocation',
  vehicleOwner = 'VehicleOwner',
  vehiclePurchaseDate = 'VehiclePurchaseDate',
  vehicleSeats = 'VehicleSeats',
  vehicleSecurityDevice = 'VehicleSecurityDevice',
  vehicleStorage = 'VehicleStorage',
  vehicleTransmission = 'VehicleTransmission',
  vehicleUse = 'VehicleUse',
  vehicleValue = 'VehicleValue',
  vehicleWeight = 'VehicleWeight',
  vehicleYearOfMake = 'VehicleYearOfMake',
  verisk = 'Verisk',
  voidReason = 'VoidReason',
  voluntaryExcess = 'VoluntaryExcess',
  wheelchairDescription = 'WheelchairDescription',
  whichCaSSOASite = 'WhichCaSSOASite',
  whoLivesAtProperty = 'WhoLivesAtProperty',
  willNotExport = 'willNotExport',
  workingAlarm = 'WorkingAlarm',
  workRadius = 'WorkRadius',
  yearOfMake = 'YearOfMake',
  yearsExperienceOnVehicle = 'YearsExperienceOnVehicle',
  hadPreviousInsurance = 'HadPreviousInsurance',
  previousInsurerName = 'PreviousInsurerName',
  previousPolicyNumber = 'PreviousPolicyNumber',
  europeanCoverRequired = 'EuropeanCoverRequired',
  isSiteRegistered = 'IsSiteRegistered',
  publicLiabilityRequired = 'PublicLiabilityRequired',
  publicLiabilityCover = 'PublicLiabilityCover',
  purchaseDate = 'PurchaseDate',
  notBoughtYet = 'NotBoughtYet',
  staticCaravanPurchaseDate = 'StaticCaravanPurchaseDate',
  annualMileage = 'AnnualMileage',
  hasHadPreviousPolicyCancelled = 'HasHadPreviousPolicyCancelled',
  hasAccessToProposerLicence = 'HasAccessToProposerLicence',
  hasAccessToAdditionalDriverLicence = 'HasAccessToAdditionalDriverLicence',
  hasConvictionBan = 'HasConvictionBan',
  banStartDate = 'BanStartDate',
  banEndDate = 'BanEndDate',
  caravanCRISNumber = 'CaravanCRISNumber',
  authorisedPersonsName = 'AuthorisedPersonsName',
  everDeclaredBankrupt = 'EverDeclaredBankrupt',
  vehicleModificationSubCategory = 'VehicleModificationSubCategory',
  vehicleModifications = 'VehicleModifications',
  hasCausedAccidentOrLegal = 'HasCausedAccidentOrLegal',
  trackerUniqueID = 'TrackerUniqueID',
  staticCaravanPlotNumber = 'StaticCaravanPlotNumber',
  staticCaravanSited = 'StaticCaravanSited',
  breakDownCoverRequired = 'BreakDownCoverPrice',
  sameAsHomeAddress = 'SameAsHomeAddress',
  studentType = 'StudentType',
  hadSpecialConditions = 'HadSpecialConditions',
  insuranceDeclined = 'InsuranceDeclined',
  yearsWithNoClaimsBuilding = 'YearsWithNoClaimsBuilding',
  yearsWithNoClaimsContents = 'YearsWithNoClaimsContents',
  buildingsVoluntaryExcess = 'BuildingsVoluntaryExcess',
  contentsVoluntaryExcess = 'ContentsVoluntaryExcess',
  homeClaimCause = 'HomeClaimCause',
  homeClaimAtCurrentProperty = 'HomeClaimAtCurrentProperty',
  claimAmount = 'ClaimAmount',
  contactPhoneNumber = 'ContactPhoneNumber',
  hasBeenBankrupt = 'HasBeenBankrupt',
  coverStartDate = 'CoverStartDate',
  bedroomCount = 'BedroomCount',
  bathroomCount = 'BathroomCount',
  livingRoomCount = 'LivingRoomCount',
  otherRoomCount = 'OtherRoomCount',
  roofMaterial = 'RoofMaterial',
  roofFlatness = 'RoofFlatness',
  externalWallMaterial = 'ExternalWallMaterial',
  anyHistoryOfSubsidence = 'AnyHistoryOfSubsidence',
  anyHistoryOfUnderpinning = 'AnyHistoryOfUnderpinning',
  anyHistoryOfFlooding = 'AnyHistoryOfFlooding',
  anyBuildingWorkInProgress = 'AnyBuildingWorkInProgress',
  propertyDistanceToWatercourse = 'PropertyDistanceToWatercourse',
  propertyIsListedBuilding = 'PropertyIsListedBuilding',
  mainHome = 'MainHome',
  homeIsInGoodStateOfRepair = 'HomeIsInGoodStateOfRepair',
  largeTreesNearby = 'LargeTreesNearby',
  anyAnimals = 'AnyAnimals',
  anySmokers = 'AnySmokers',
  whenAtProperty = 'WhenAtProperty',
  howLongPropertyUnoccupied = 'HowLongPropertyUnoccupied',
  anyBusinessUse = 'AnyBusinessUse',
  typeOfAlarm = 'TypeOfAlarm',
  typeOfLocks = 'TypeOfLocks',
  lessSecureDoors = 'LessSecureDoors',
  otherTypeOfLocks = 'OtherTypeOfLocks',
  hasKeyOperatedLocksOnAllWindows = 'HasKeyOperatedLocksOnAllWindows',
  workingSmokeDetectors = 'WorkingSmokeDetectors',
  inVerifiedNeighbourhoodWatchArea = 'InVerifiedNeighbourhoodWatchArea',
  vehicleRegistration = 'VehicleRegistration',
}

export default QuestionEnum
