import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import QuestionEnum from '../services/enums/QuestionEnum'
import type { DefinedListDetail } from '../services/models/DefinedList'
import type Page from './Page'
import type IQuestion from '@/services/interfaces/IQuestion'
import type Address from '@/services/models/Address'
import type Vehicle from '@/services/values/Vehicle'
import InputComponentTypeEnum from '@/services/enums/InputComponentTypeEnum'
import type HTMLInputTypeEnum from '@/services/enums/HTMLInputTypeEnum'
import type { Dictionary } from '@/types'
import PageAnswers, { QuestionAnswer } from '@/services/values/PageAnswers'
import DLDCommonValuesEnum from '@/services/enums/DLDCommonValuesEnum'

export default class Question<T> implements IQuestion {
  id: number

  label: string

  name: QuestionEnum

  type: InputComponentTypeEnum

  required: boolean

  placeholder: string | null

  tooltip: string | null

  validationMessage: string | null = null

  showSubPageOnLoad: boolean

  config: Dictionary<string> = {}

  value: T | null = null

  visible: boolean

  disableEditInSummaryMode: boolean

  dependentQuestions: IQuestion[]

  markdown: string | null = null

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    type: InputComponentTypeEnum,
    required: boolean,
    placeholder: string | null = null,
    tooltip: string | null = null,
    value: T | null = null,
    config: Dictionary<string> = {},
    visible = true,
    dependentQuestions = [],
    validationMessage: string | null = null,
    showSubPageOnLoad: false,
    disableEditInSummaryMode: false,
  ) {
    this.id = id
    this.label = label
    this.name = name
    this.type = type
    this.required = required
    this.placeholder = placeholder
    this.tooltip = tooltip
    this.value = value
    this.config = config
    this.visible = visible
    this.dependentQuestions = dependentQuestions
    this.validationMessage = validationMessage
    this.showSubPageOnLoad = showSubPageOnLoad
    this.disableEditInSummaryMode = disableEditInSummaryMode
  }

  hasValue(): boolean {
    return this.value !== null
  }

  isVisible(): boolean {
    return this.visible
  }

  isRequired(): boolean {
    return this.required
  }

  isEditDisabledInSummaryMode(): boolean {
    return this.disableEditInSummaryMode
  }

  isShowSubPageOnLoad(): boolean {
    return this.showSubPageOnLoad
  }

  toAnswer(): QuestionAnswer<T> {
    return new QuestionAnswer<T>(this.id, this.name, this.label, this.value, this.isVisible(), this.display(), [])
  }

  display(): string | null {
    if (!this.value)
      return null
    if (typeof this.value === 'string')
      return this.value.toString()
    else if (typeof this.value === 'number')
      return this.value.toString()
    else if (typeof this.value === 'boolean')
      return this.value ? 'Yes' : 'No'
    else if (typeof this.value === 'undefined')
      return 'Not Set'
    else if (Array.isArray(this.value))
      return this.value.toString()

    throw new Error(`${this.name} - type (${typeof this.value}) is an unknown display type.`)
  }

  hideOnSummary(): boolean | null {
    return false
  }
}

export class DateQuestion extends Question<Date> {
  minDate = (): Date | null => null

  maxDate = (): Date | null => null

  isDisplayDateBtns = (): boolean | null => null

  incrementMinutes = (): number | null => null

  focusedDate = (): Date | null => null

  isDateTime = (): boolean => false

  isDisableTime = (): boolean => false

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    type: InputComponentTypeEnum,
    required: boolean,
    isDateTimeValue: boolean,
    disableTime: boolean,
    placeholder: string | null = null,
    tooltip: string | null = null,
    minDateValue: Date | null = null,
    maxDateValue: Date | null = null,
    incrementMinutesValue: number | null = null,
    displayDateBtns: boolean | null = null,
    visible = true,
    config: Dictionary<string> = {},
  ) {
    super(id, label, name, type, required, placeholder, tooltip, null, config)
    this.visible = visible
    this.isDateTime = () => isDateTimeValue
    this.isDisableTime = () => disableTime
    this.minDate = () => minDateValue
    this.maxDate = () => maxDateValue
    this.incrementMinutes = () => incrementMinutesValue
    this.isDisplayDateBtns = () => displayDateBtns
  }

  display(): string | null {
    if (!this.value)
      return null
    return moment(this.value).format(`DD/MM/YYYY${this.isDateTime() ? ' HH:mm' : ''}`)
  }
}

export class TextQuestion extends Question<string> {
  textType: HTMLInputTypeEnum

  regex: string | null

  regexValidationError: string | null

  maxlength: number

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    textType: HTMLInputTypeEnum,
    required: boolean,
    maxlength = 255,
    placeholder: string | null = null,
    tooltip: string | null = null,
    config: Dictionary<string> = {},
    regex: string | null = null,
    regexValidationError: string | null = null,
    visible = true,
  ) {
    super(id, label, name, InputComponentTypeEnum.text, required, placeholder, tooltip, null, config)
    this.visible = visible
    this.textType = textType
    this.regex = regex
    this.maxlength = maxlength
    this.regexValidationError = regexValidationError
  }
}

export class VehicleRegistrationQuestion extends Question<string> {
  maxlength: number

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    required: boolean,
    maxlength = 7,
    placeholder: string | null = null,
    tooltip: string | null = null,
    config: Dictionary<string> = {},
    visible = true,
  ) {
    super(id, label, name, InputComponentTypeEnum.vehicleRegistrationInput, required, placeholder, tooltip, null, config)
    this.visible = visible
    this.maxlength = maxlength
  }
}

export class NumberQuestion extends Question<number> {
  min: number | null

  max: number | null

  dependentMaxVisible: boolean

  dependentMaxMessage: string | null

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    required: boolean,
    placeholder: string | null = null,
    tooltip: string | null = null,
    min: number | null = null,
    max: number | null = null,
    config: Dictionary<string> = {},
    visible = true,
    dependentMaxVisible = false,
    dependentMaxMessage: string | null = null,
  ) {
    super(id, label, name, InputComponentTypeEnum.number, required, placeholder, tooltip, null, config, visible)
    this.min = min
    this.max = max
    this.dependentMaxVisible = dependentMaxVisible
    this.dependentMaxMessage = dependentMaxMessage
  }

  display(): string | null {
    if (this.value === 0 || this.value)
      return this.value.toString()

    return null
  }

  hasValue(): boolean {
    return !!(this.value === 0 || this.value)
  }

  setdependentMaxVisible(): boolean {
    return this.dependentMaxVisible
  }

  setdependentMaxMessage(): string {
    return `${this.dependentMaxMessage}`
  }
}

export class MarkdownQuestion extends Question<string> {
  markdown: string | null

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    required: boolean,
    placeholder: string | null = null,
    tooltip: string | null = null,
    markdown: string | null = null,
    config: Dictionary<string> = {},
    visible = true,
  ) {
    super(id, label, name, InputComponentTypeEnum.markdown, required, placeholder, tooltip, null, config, visible)
    this.markdown = markdown
  }
}

export class SelectOption<T> {
  name: string

  value: T

  dependencyId: number

  OptionAsBtn: boolean

  tooltip: string

  disabled: boolean

  isDisabled(): boolean {
    return this.disabled
  }

  constructor(name: string, value: T, optionAsBtn: boolean, dependencyId?: number, tooltip?: string, disabled?: boolean) {
    this.name = name
    this.value = value
    this.dependencyId = dependencyId!
    this.OptionAsBtn = optionAsBtn
    this.tooltip = tooltip!
    this.disabled = disabled!
    this.isDisabled = () => this.disabled
  }
}

export class SelectQuestion<T, S> extends Question<T> {
  options: SelectOption<S>[]

  isDisplayAsBtns = (): boolean | null => null

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    type: InputComponentTypeEnum,
    required: boolean,
    options: SelectOption<S>[],
    placeholder: string | null = null,
    tooltip: string | null = null,
    value: T | null = null,
    config: Dictionary<string> = {},
    visible = true,
  ) {
    super(id, label, name, type, required, placeholder, tooltip, value, config, visible)
    this.options = options
  }

  display(): string | null {
    if (!this.value)
      return null
    if (typeof this.value === 'string') {
      const { value } = this
      const option = this.options.find(o => typeof o.value === 'string' && o.value.toString() === value.toString())
      return option?.name ?? null
    }
    else if (typeof this.value === 'number') {
      const numberValue = this.value
      const option = this.options.find(o => typeof o.value === 'number' && o.value.toString() === numberValue.toString())
      return option?.name ?? null
    }
    else if (typeof this.value === 'undefined') {
      return 'Not Selected'
    }
    else if (Array.isArray(this.value)) {
      const names: string[] = []
      if (this.options.length === 0 && this.value.length === 1)
        return this.value[0]

      this.value.forEach((v) => {
        const name = this.options?.find(o => typeof o.value === 'string' && o.value?.toString() === v.toString())?.name
        if (name !== undefined)
          names.push(name)
      })
      return names.join(', ')
    }
    throw new Error(`${this.name} - type (${typeof this.value}) is an unknown display type.`)
  }
}

export class RelatedSelectQuestion extends SelectQuestion<string, string> {
  unfilteredOptions: DefinedListDetail[]

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    type: InputComponentTypeEnum,
    required: boolean,
    options: SelectOption<string>[],
    unfilteredOptions: DefinedListDetail[],
    placeholder: string | null,
    tooltip: string | null,
    value: string | null,
    config: Dictionary<string> = {},
    visible = true,
  ) {
    super(id, label, name, type, required, options, placeholder, tooltip, value, config, visible)
    this.unfilteredOptions = unfilteredOptions
  }

  filtered(val: string): SelectOption<string>[] {
    return this.unfilteredOptions.filter(x => x.relatedUniqueId === val).map(
      dld => new SelectOption<string>(dld.name, dld.uniqueId, dld.optionAsBtn),
    )
  }
}

export class RelatedRadioList extends RelatedSelectQuestion {
  display(): string | null {
    if (!this.value)
      return null

    if (this.value === DLDCommonValuesEnum.RelatedRadioListEnum.OtherValue)
      return 'Other Value'

    if (typeof this.value === 'string') {
      const { value } = this
      const option = this.options.find(o => typeof o.value === 'string' && o.value.toString() === value.toString())

      return option?.name ?? null
    }

    throw new Error(`${this.name} - type (${typeof this.value}) is an unknown display type.`)
  }

  hideOnSummary(): boolean | null {
    return this.value === DLDCommonValuesEnum.RelatedRadioListEnum.OtherValue
  }
}

export class RelatedCustomSelectQuestion extends SelectQuestion<string, number> {
  unfilteredOptions: SelectOption<number>[] = []

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    type: InputComponentTypeEnum,
    required: boolean,
    options: SelectOption<number>[],
    unfilteredOptions: SelectOption<number>[],
    placeholder: string | null,
    tooltip: string | null,
    value: string | null,
    config: Dictionary<string> = {},
    visible = true,
  ) {
    super(id, label, name, type, required, options, placeholder, tooltip, value, config, visible)
    this.unfilteredOptions = unfilteredOptions
  }

  filtered(val: number): SelectOption<number>[] {
    return this.unfilteredOptions.filter(x => x.dependencyId === val).map(
      dld => new SelectOption<number>(dld.name, dld.value, true, dld.dependencyId),
    )
  }
}

export class DrivingLicenceQuestion extends Question<string> {
  autoPopulate: boolean

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    required: boolean,
    autoPopulate: boolean,
    placeholder: string | null = null,
    tooltip: string | null = null,
  ) {
    super(id, label, name, InputComponentTypeEnum.ukDrivingLicence, required, placeholder, tooltip)
    this.autoPopulate = autoPopulate
  }
}

export class VehicleLookupQuestion extends Question<Vehicle> {
  value: Vehicle | null = null

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    required: boolean,
    placeholder: string | null,
    tooltip: string | null,
    config: Dictionary<string>,
    visible: boolean,
  ) {
    super(id, label, name, InputComponentTypeEnum.vehicleLookup, required, placeholder, tooltip, null, config, visible)
  }

  get dvlaOnly(): boolean {
    return this.config.dvlaOnly === 'true'
  }

  get manualMakeModel(): boolean {
    return this.config.manualMakeModel === 'true'
  }

  get showEngineSize(): boolean {
    return this.config.showEngineSize === 'true'
  }

  get showManualFuelType(): boolean {
    return this.config.showManualFuelType === 'true'
  }

  get hidePurchaseDate(): boolean {
    return this.config.hidePurchaseDate === 'true'
  }

  get showDateVehicleFirstRegistered(): boolean {
    return this.config.showDateVehicleFirstRegistered === 'true'
  }

  get showBodyType(): boolean {
    return this.config.showBodyType === 'true'
  }

  get hideCarValueSummary(): boolean {
    return this.config.hideCarValueSummary === 'true'
  }

  get hideFuel(): boolean {
    return this.config.hideFuel === 'true'
  }

  get hideYearOfManufacture(): boolean {
    return this.config.hideYearOfManufacture === 'true'
  }

  get showManualEngineSize(): boolean {
    return this.config.showManualEngineSize === 'true'
  }

  get showManualValue(): boolean {
    return this.config.showManualValue === 'true'
  }

  get showManualPurchaseDate(): boolean {
    return this.config.showManualPurchaseDate === 'true'
  }

  get showManualDateVehicleFirstRegistered(): boolean {
    return this.config.showManualDateVehicleFirstRegistered === 'true'
  }

  get showManualYearOfManufacture(): boolean {
    return this.config.showManualYearOfManufacture === 'true'
  }

  display(): string | null {
    if (!this.value)
      return null
    return `${this.value.registration} (${this.value.make}, ${this.value.model}, ${this.value.yearOfManufacture}, £${this.value.value}) ${this.showEngineSize ? `, ${this.value.engineSize}` : ''} ${this.showBodyType ? `, ${this.value.bodyType}` : ''}`
  }
}

export class AddressQuestion extends Question<Address> {
  value: Address | null = null

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    required: boolean,
    placeholder: string | null = null,
    tooltip: string | null = null,
  ) {
    super(id, label, name, InputComponentTypeEnum.address, required, placeholder, tooltip)
  }

  display(): string | null {
    if (!this.value)
      return null
    let address = this.value.line1
    if (this.value.line2)
      address += `, ${this.value.line2}`

    if (this.value.line3)
      address += `, ${this.value.line3}`

    return `${address}, ${this.value.city}, ${this.value.postCode}`
  }
}

export class VeriskQuestion extends Question<string> {
  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    required: boolean,
    placeholder: string | null = null,
    tooltip: string | null = null,
  ) {
    super(id, label, name, InputComponentTypeEnum.verisk, required, placeholder, tooltip)
  }

  display(): string | null {
    if (!this.value)
      return null
    return `${this.value}`
  }
}

export class SubPageDetailQuestion {
  page: Page
  uuid: string
  toggle: boolean

  constructor(page: Page) {
    this.page = page
    this.uuid = uuidv4()
    this.toggle = false
  }
}

export class SubPagesQuestion extends Question<number> {
  subPageDetails: SubPageDetailQuestion[]

  minSubPages: number

  maxSubPages: number

  setId: string | null

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    required: boolean,
    type: InputComponentTypeEnum,
    subPageDetails: SubPageDetailQuestion[],
    minSubPages: number,
    maxSubPages: number,
    tooltip: string | null = null,
    setId: string | null = null,
    config: Dictionary<string> = {},
    visible: boolean = true,
  ) {
    super(id, label, name, type, required, null, tooltip)
    this.subPageDetails = subPageDetails
    this.minSubPages = minSubPages
    this.maxSubPages = maxSubPages
    this.setId = setId
    this.config = config
    this.visible = visible
  }

  removeSubPage(index: number): void {
    this.subPageDetails.splice(index, 1)
  }

  display(): string | null {
    throw new Error('Display function not implemented for base SubPageQuestion.')
  }
}

export class StorageLocationQuestion extends SubPagesQuestion {
  display(): string | null {
    if (this.subPageDetails.length < 1)
      return null
    const summary: string[] = []
    this.subPageDetails.forEach((adp) => {
      summary.push(adp.page.title)
    })
    return summary.join('\n')
  }

  toAnswer(): QuestionAnswer<number> {
    const base = super.toAnswer()
    base.subPageAnswers = this.subPageDetails.map((add, index) => {
      const title = index === 0 ? 'Primary' : 'Secondary'
      return new PageAnswers(title, add.page.sections.flatMap(s => s.questions).map(q => q.toAnswer()))
    })
    return base
  }
}

export class PetsQuestion extends SubPagesQuestion {
  display(): string | null {
    if (this.subPageDetails.length < 1)
      return null
    const summary: string[] = []
    this.subPageDetails.forEach((adp) => {
      summary.push(adp.page.title)
    })
    return summary.join('\n')
  }

  toAnswer(): QuestionAnswer<number> {
    const base = super.toAnswer()
    base.subPageAnswers = this.subPageDetails.map((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const name = questions.find(q => q.name === QuestionEnum.name) as TextQuestion
      const title = name.value!
      return new PageAnswers(title, add.page.sections.flatMap(s => s.questions).map(q => q.toAnswer()))
    })
    return base
  }
}

export class AdditionalPersonsQuestion extends SubPagesQuestion {
  display(): string | null {
    if (this.subPageDetails.length < 1)
      return null
    const summary: string[] = []
    this.subPageDetails.forEach((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const firstName = questions.find(q => q.name === QuestionEnum.firstname) as TextQuestion
      const lastName = questions.find(q => q.name === QuestionEnum.lastName) as TextQuestion
      if (firstName && lastName)
        summary.push(`${firstName.value} ${lastName.value}`)

      const dateOfBirth = questions.find(q => q.name === QuestionEnum.dateOfBirth) as DateQuestion
      if (dateOfBirth)
        summary.push(`${dateOfBirth.value}`)

      const licence = questions.find(q => q.name === QuestionEnum.drivingLicence) as DrivingLicenceQuestion
      if (licence)
        summary.push(`${licence.value}`)
    })
    return summary.join(', ')
  }

  toAnswer(): QuestionAnswer<number> {
    const base = super.toAnswer()
    base.subPageAnswers = this.subPageDetails.map((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const firstName = questions.find(q => q.name === QuestionEnum.firstname) as TextQuestion
      const lastName = questions.find(q => q.name === QuestionEnum.lastName) as TextQuestion
      const title = `Additional ${this.config.subPageType} - ${firstName.value} ${lastName.value}`

      return new PageAnswers(title, add.page.sections.flatMap(s => s.questions).map(q => q.toAnswer()))
    })
    return base
  }
}

export class SpecifiedItemsQuestion extends SubPagesQuestion {
  display(): string | null {
    if (this.subPageDetails.length < 1)
      return null
    const summary: string[] = []
    this.subPageDetails.forEach((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const typeOfItem = questions.find(q => q.name === QuestionEnum.typeOfItem) as SelectQuestion<string, string>
      const specifiedItem = questions.find(q => q.name === QuestionEnum.specifiedItemDescription) as TextQuestion
      const replacementCost = questions.find(q => q.name === QuestionEnum.replacementCost) as NumberQuestion
      const insuredItemsCarriedOutsideProperty = questions.find(q => q.name === QuestionEnum.insuredItemsCarriedOutsideProperty) as SelectQuestion<string, string>

      if (typeOfItem)
        summary.push(`${typeOfItem.value}`)

      if (specifiedItem)
        summary.push(`${specifiedItem.value}`)

      if (replacementCost)
        summary.push(`${replacementCost.value}`)

      if (insuredItemsCarriedOutsideProperty)
        summary.push(`${insuredItemsCarriedOutsideProperty.value}`)
    })
    return summary.join(', ')
  }

  total(): number {
    if (this.subPageDetails.length < 1)
      return 0
    let replacementCostTotal: number = 0
    this.subPageDetails.forEach((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const replacementCost = questions.find(q => q.name === QuestionEnum.replacementCost) as NumberQuestion

      if (replacementCost)
        replacementCostTotal += replacementCost.value!
    })
    return replacementCostTotal
  }

  toAnswer(): QuestionAnswer<number> {
    const base = super.toAnswer()
    base.subPageAnswers = this.subPageDetails.map((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const typeOfItem = questions.find(q => q.name === QuestionEnum.typeOfItem) as SelectQuestion<string, string>
      const specifiedItem = questions.find(q => q.name === QuestionEnum.specifiedItemDescription) as TextQuestion
      const replacementCost = questions.find(q => q.name === QuestionEnum.replacementCost) as NumberQuestion
      const insuredItemsCarriedOutsideProperty = questions.find(q => q.name === QuestionEnum.insuredItemsCarriedOutsideProperty) as SelectQuestion<string, string>
      const title = `${typeOfItem.value} ${specifiedItem.value} ${replacementCost.value} ${insuredItemsCarriedOutsideProperty}`

      return new PageAnswers(title, add.page.sections.flatMap(s => s.questions).map(q => q.toAnswer()))
    })
    return base
  }
}

export class AdditionalVehiclesQuestion extends SubPagesQuestion {
  display(): string | null {
    if (this.subPageDetails.length < 1)
      return null
    const summary: string[] = []
    this.subPageDetails.forEach((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const vehicleLookup = questions.find(q => q.name === QuestionEnum.vehicleLookup) as VehicleLookupQuestion

      summary.push(`${vehicleLookup.value?.registration} ${vehicleLookup.value?.yearOfManufacture} ${vehicleLookup.value?.make} ${vehicleLookup.value?.model}`)
    })
    return summary.join('\n')
  }

  toAnswer(): QuestionAnswer<number> {
    const base = super.toAnswer()
    base.subPageAnswers = this.subPageDetails.map((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const vehicleLookup = questions.find(q => q.name === QuestionEnum.vehicleLookup) as VehicleLookupQuestion
      const title = `${vehicleLookup.value?.yearOfManufacture} ${vehicleLookup.value?.make} ${vehicleLookup.value?.model}`

      return new PageAnswers(title, add.page.sections.flatMap(s => s.questions).map(q => q.toAnswer()))
    })
    return base
  }
}

export class ConvictionsQuestion extends SubPagesQuestion {
  display(): string | null {
    if (this.subPageDetails.length < 1)
      return null
    const summary: string[] = []
    this.subPageDetails.forEach((add) => {
      const convictionSummary: string[] = []
      const questions = add.page.sections.flatMap(s => s.questions)
      const convictionType = questions.find(q => q.name === QuestionEnum.convictionType) as SelectQuestion<string, string>
      convictionSummary.push(`${convictionType ? convictionType.display() : ''}`)
      const convictionDate = questions.find(q => q.name === QuestionEnum.convictionDate) as DateQuestion
      convictionSummary.join(`${convictionDate ? `-${moment(convictionDate.value).format('DD/MM/YYYY')}` : ''}`)
      const convictionPersonsInjured = questions.find(q => q.name === QuestionEnum.convictionPersonsInjured) as SelectQuestion<string, string>
      convictionSummary.join(`${convictionPersonsInjured ? `,${convictionPersonsInjured.display()}` : ''}`)
      const convictionDisqualificationMonths = questions.find(q => q.name === QuestionEnum.convictionDisqualificationMonths) as SelectQuestion<string, string>
      convictionSummary.join(`${convictionDisqualificationMonths ? `,${convictionDisqualificationMonths.display()}` : ''}`)
      const convictionFine = questions.find(q => q.name === QuestionEnum.convictionFine) as SelectQuestion<string, string>
      convictionSummary.join(`${convictionFine ? `,${convictionFine.display()}` : ''}`)
      const convictionPointsReceived = questions.find(q => q.name === QuestionEnum.convictionPointsReceived) as SelectQuestion<string, string>
      convictionSummary.join(`${convictionPointsReceived ? `,${convictionPointsReceived.display()}` : ''}`)
      summary.push(`${convictionSummary}`)
    })
    return summary.join('\n')
  }

  toAnswer(): QuestionAnswer<number> {
    const base = super.toAnswer()
    base.subPageAnswers = this.subPageDetails.map((add) => {
      const convictionSummary: string[] = []
      const questions = add.page.sections.flatMap(s => s.questions)
      const convictionType = questions.find(q => q.name === QuestionEnum.convictionType) as SelectQuestion<string, string>
      convictionSummary.push(`${convictionType ? convictionType.display() : ''}`)
      const convictionDate = questions.find(q => q.name === QuestionEnum.convictionDate) as DateQuestion
      convictionSummary.join(`${convictionDate ? `-${moment(convictionDate.value).format('DD/MM/YYYY')}` : ''}`)
      const convictionPersonsInjured = questions.find(q => q.name === QuestionEnum.convictionPersonsInjured) as SelectQuestion<string, string>
      convictionSummary.join(`${convictionPersonsInjured ? `,${convictionPersonsInjured.display()}` : ''}`)
      const convictionDisqualificationMonths = questions.find(q => q.name === QuestionEnum.convictionDisqualificationMonths) as SelectQuestion<string, string>
      convictionSummary.join(`${convictionDisqualificationMonths ? `,${convictionDisqualificationMonths.display()}` : ''}`)
      const convictionFine = questions.find(q => q.name === QuestionEnum.convictionFine) as SelectQuestion<string, string>
      convictionSummary.join(`${convictionFine ? `,${convictionFine.display()}` : ''}`)
      const convictionPointsReceived = questions.find(q => q.name === QuestionEnum.convictionPointsReceived) as SelectQuestion<string, string>
      convictionSummary.join(`${convictionPointsReceived ? `,${convictionPointsReceived.display()}` : ''}`)
      const title = convictionSummary.toString()
      return new PageAnswers(title, add.page.sections.flatMap(s => s.questions).map(q => q.toAnswer()))
    })
    return base
  }
}

export class ModificationQuestion extends SubPagesQuestion {
  display(): string | null {
    if (this.subPageDetails.length < 1)
      return null
    const summary: string[] = []
    this.subPageDetails.forEach((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const mod = questions.find(q => q.name === QuestionEnum.modification || q.name === QuestionEnum.vehicleModifications) as SelectQuestion<string, string>
      summary.push(`${mod.display()}`)
    })
    return summary.join('\n')
  }

  toAnswer(): QuestionAnswer<number> {
    const base = super.toAnswer()
    base.subPageAnswers = this.subPageDetails.map((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const mod = questions.find(q => q.name === QuestionEnum.modification || q.name === QuestionEnum.vehicleModifications) as SelectQuestion<string, string>
      const title = `${mod.display()}`
      return new PageAnswers(title, add.page.sections.flatMap(s => s.questions).map(q => q.toAnswer()))
    })
    return base
  }
}

export class MedicalConditionsQuestion extends SubPagesQuestion {
  display(): string | null {
    if (this.subPageDetails.length < 1)
      return null
    const summary: string[] = []
    this.subPageDetails.forEach((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const incidentDate = questions.find(q => q.name === QuestionEnum.incidentDate) as DateQuestion
      const medicalCondition = questions.find(q => q.name === QuestionEnum.medicalCondition) as SelectQuestion<string, string>
      const hasInformedDVLAOfMedicalConditionsAndDisabilities = questions.find(q => q.name === QuestionEnum.hasInformedDVLAOfMedicalConditionsAndDisabilities) as SelectQuestion<string, string>
      const dVLAReportableMedicalConditions = questions.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as SelectQuestion<string, string>
      summary.push(`${medicalCondition.display()} - ${moment(incidentDate.value).format('DD/MM/YYYY')}, ${hasInformedDVLAOfMedicalConditionsAndDisabilities.display()}, ${dVLAReportableMedicalConditions.display()}`)
    })
    return summary.join('\n')
  }

  toAnswer(): QuestionAnswer<number> {
    const base = super.toAnswer()
    base.subPageAnswers = this.subPageDetails.map((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const incidentDate = questions.find(q => q.name === QuestionEnum.incidentDate) as DateQuestion
      const medicalCondition = questions.find(q => q.name === QuestionEnum.medicalCondition) as SelectQuestion<string, string>
      const hasInformedDVLAOfMedicalConditionsAndDisabilities = questions.find(q => q.name === QuestionEnum.hasInformedDVLAOfMedicalConditionsAndDisabilities) as SelectQuestion<string, string>
      const dVLAReportableMedicalConditions = questions.find(q => q.name === QuestionEnum.dvlaReportableMedicalConditions) as SelectQuestion<string, string>
      const title = `${medicalCondition.display()} - ${moment(incidentDate.value).format('DD/MM/YYYY')}, ${hasInformedDVLAOfMedicalConditionsAndDisabilities.display()}, ${dVLAReportableMedicalConditions.display()}`
      return new PageAnswers(title, add.page.sections.flatMap(s => s.questions).map(q => q.toAnswer()))
    })
    return base
  }
}

export class ClaimsQuestion extends SubPagesQuestion {
  display(): string | null {
    if (this.subPageDetails.length < 1)
      return null
    const summary: string[] = []
    this.subPageDetails.forEach((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const claimIncidentType = questions.find(q => q.name === QuestionEnum.claimIncidentType) as SelectQuestion<string, string>
      const claimDate = questions.find(q => q.name === QuestionEnum.claimDate) as DateQuestion
      const claimType = questions.find(q => q.name === QuestionEnum.claimType) as SelectQuestion<string, string>
      const claimStatus = questions.find(q => q.name === QuestionEnum.claimStatus) as SelectQuestion<string, string>
      const atFault = questions.find(q => q.name === QuestionEnum.claimFault) as SelectQuestion<string, string>
      const claimCost = questions.find(q => q.name === QuestionEnum.claimCost) as NumberQuestion

      if (claimIncidentType)
        summary.push(`${moment(claimDate?.value).format('DD/MM/YYYY')} - ${claimIncidentType?.display()} - £${claimCost?.value?.toFixed(2)}`)
      else
        summary.push(`${moment(claimDate?.value).format('DD/MM/YYYY')} - ${claimType?.display()}, ${claimStatus?.display()} (${atFault?.display()}) £${claimCost?.value?.toFixed(2)}`)
    })
    return summary.join('\n')
  }

  toAnswer(): QuestionAnswer<number> {
    const base = super.toAnswer()
    base.subPageAnswers = this.subPageDetails.map((add) => {
      const questions = add.page.sections.flatMap(s => s.questions)
      const claimIncidentType = questions.find(q => q.name === QuestionEnum.claimIncidentType) as SelectQuestion<string, string>
      const claimDate = questions.find(q => q.name === QuestionEnum.claimDate) as DateQuestion
      const claimType = questions.find(q => q.name === QuestionEnum.claimType) as SelectQuestion<string, string>
      const claimStatus = questions.find(q => q.name === QuestionEnum.claimStatus) as SelectQuestion<string, string>
      const atFault = questions.find(q => q.name === QuestionEnum.claimFault) as SelectQuestion<string, string>
      const claimCost = questions.find(q => q.name === QuestionEnum.claimCost) as NumberQuestion
      const title = claimIncidentType
        ? `${moment(claimDate?.value).format('DD/MM/YYYY')} - ${claimIncidentType?.display()} - £${claimCost?.value?.toFixed(2)}`
        : `${moment(claimDate?.value).format('DD/MM/YYYY')} - ${claimType?.display()}, ${claimStatus?.display()} (${atFault?.display()}) £${claimCost?.value?.toFixed(2)}`
      return new PageAnswers(title, add.page.sections.flatMap(s => s.questions).map(q => q.toAnswer()))
    })
    return base
  }
}

export class TextConfirmQuestion extends Question<string> {
  textType: HTMLInputTypeEnum

  regex: string | null

  regexValidationError: string | null

  maxlength: number

  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    type: InputComponentTypeEnum,
    textType: HTMLInputTypeEnum,
    required: boolean,
    maxlength = 255,
    placeholder: string | null = null,
    tooltip: string | null = null,
    config: Dictionary<string> = {},
    regex: string | null = null,
    regexValidationError: string | null = null,
    visible = true,
  ) {
    super(id, label, name, InputComponentTypeEnum.textConfirm, required, placeholder, tooltip, null, config)
    this.visible = visible
    this.regex = regex
    this.textType = textType
    this.maxlength = maxlength
    this.regexValidationError = regexValidationError
  }
}

export class ModalQuestion extends Question<string[]> {
  constructor(
    id: number,
    label: string,
    name: QuestionEnum,
    type: InputComponentTypeEnum.modal,
    required: boolean,
    tooltip: string | null = null,
    value: string[] | null = null,
    config: Dictionary<string> = {},
    visible = true,
  ) {
    super(id, label, name, type, required, null, tooltip, value, config, visible)
  }
}
