import Analytics from './Analytics'
import { loadGoogleTagManager, loadHotjar, loadSmartlookClient } from '@/composables/analytics-utils'

export default class YogaAnalytics extends Analytics {
  loadAnalytics(): void {
    // Google Tag Manager
    loadGoogleTagManager(window.injectedEnv.FRONTEND_GTM)

    // SmartLook
    loadSmartlookClient(window.injectedEnv.FRONTEND_SMARTLOOK)

    // Hotjar Tracking
    loadHotjar(window.injectedEnv.FRONTEND_HOTJAR)
  }
}
