import moment from 'moment'
import { ModalProgrammatic } from 'buefy'
import type { Route } from 'vue-router'
import Utils from './Utils'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'
import Routes from '@/constants/Routes'
import type { PageNavigationConfig } from '@/view-models/PageNavigationConfig'
import LinkConfigModal from '@/components/LinkConfigModal.vue'
import type { ILinkConfigSpecification } from '@/configurations/config-objects/LinkConfigSpecification'
import LinkConfigFactory from '@/configurations/LinkConfigFactory'

export default class YogaUtils extends Utils {
  getRenewalDate<T extends SchemeQuoteResult>(quote: QuoteDetails<T>, selectedSchemeQuoteResult: SchemeQuoteResult | null): Date {
    if (selectedSchemeQuoteResult!.scheme.isPayMonthly) {
      return moment(quote.effectiveDate)
        .add(1, 'month')
        .add(-1, 'day')
        .hours(23)
        .minutes(59)
        .seconds(59)
        .toDate()
    }
    else {
      return moment(quote.effectiveDate)
        .add(1, 'year')
        .add(-1, 'day')
        .hours(23)
        .minutes(59)
        .seconds(59)
        .toDate()
    }
  }

  specialMtaPreviousClickAction(from: Route, to: Routes, bypass?: boolean): PageNavigationConfig | null {
    if (from.name === Routes.mtaScheme && to === Routes.mtaReasonsForChanges) {
      if (bypass) {
        return {}
      }
      else {
        const modalConfig = {
          title: 'Are you sure?',
          message: 'Please note if you return to the previous page, all changes you just made will be lost',
          buttons: [{
            name: 'Yes, start again',
            action: {
              type: 'emit',
              url: 'modalPreviousClicked',
            },
          }, {
            name: 'No, continue with changes',
            action: {
              type: 'emit',
              url: 'close',
            },
          }],
        }
        ModalProgrammatic.open({
          component: LinkConfigModal,
          hasModalCard: true,
          canCancel: ['escape', 'outside'],
          props: { title: modalConfig.title, message: modalConfig.message, buttons: modalConfig.buttons.map(b => LinkConfigFactory.create(b as ILinkConfigSpecification)),
          },
        })
        return null
      }
    }
    return {}
  }
}
