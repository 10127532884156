import moment from 'moment'
import { SnackbarProgrammatic } from 'buefy'
import type { ValidationObserver } from 'vee-validate'
import type { Route } from 'vue-router'
import GapQuoteDetails from '../services/models/GapQuoteDetails'
import TouringCaravanQuoteDetails from '../services/models/TouringCaravanQuoteDetails'
import type IUtils from './IUtils'
import type IQuestion from '@/services/interfaces/IQuestion'
import type { Dictionary } from '@/types'
import QuestionEnum from '@/services/enums/QuestionEnum'
import type { SubPagesQuestion } from '@/view-models/Question'
import PaymentTypeEnum from '@/services/enums/PaymentTypeEnum'
import PaymentScheduleStatusEnum from '@/services/enums/PaymentScheduleStatusEnum'
import type QuoteDetails from '@/services/models/QuoteDetails'
import { CarQuoteDetails } from '@/services/models/CarQuoteDetails'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'
import type { IQuestionAnswer, QuestionAnswer } from '@/services/values/PageAnswers'
import type PageAnswers from '@/services/values/PageAnswers'
import VehicleQuoteDetails from '@/services/models/Vehicle/VehicleQuoteDetails'
import useCookieStore from '@/stores/cookie-store'
import PetQuoteDetails from '@/services/models/PetQuoteDetails'
import CaravanQuoteDetails from '@/services/models/CaravanQuoteDetails'
import type ProductEnum from '@/services/enums/ProductEnum'
import type IConfiguration from '@/configurations/IConfiguration'
import PropertyQuoteDetails from '@/services/models/Property/PropertyQuoteDetails'
import type { PageNavigationConfig } from '@/view-models/PageNavigationConfig'
import type Routes from '@/constants/Routes'

export default class Utils implements IUtils {
  getPaymentTypeText(paymentTypeEnum: PaymentTypeEnum): string | null {
    switch (paymentTypeEnum) {
      case PaymentTypeEnum.cardPayment:
        return 'Credit Card'
      case PaymentTypeEnum.directDebit:
        return 'Direct Debit'
      case PaymentTypeEnum.agentAccount:
        return 'Agent Account'
      case PaymentTypeEnum.bacs:
        return 'BACS'
      case PaymentTypeEnum.cash:
        return 'Cash'
      case PaymentTypeEnum.cheque:
        return 'Cheque'
      case PaymentTypeEnum.goCardless:
        return 'GoCardless'
      case PaymentTypeEnum.instalments:
        return 'Instalments'
      case PaymentTypeEnum.ManualCardPayment:
        return 'Finance PDQ Machine Payment'
      case PaymentTypeEnum.manualInstallmentPayment:
        return 'Manual Instalment Payment'
      default:
        return null
    }
  }

  getPaymentScheduleStatusText(paymentScheduleStatusEnum: PaymentScheduleStatusEnum): string {
    switch (paymentScheduleStatusEnum) {
      case PaymentScheduleStatusEnum.notYetDue:
        return 'Not Yet Due'
      case PaymentScheduleStatusEnum.sent:
        return 'Sent'
      case PaymentScheduleStatusEnum.inLoop:
        return 'In Loop'
      case PaymentScheduleStatusEnum.paid:
        return 'Paid'
      case PaymentScheduleStatusEnum.failed:
        return 'Failed'
      case PaymentScheduleStatusEnum.paymentFailed:
        return 'Payment Failed'
      case PaymentScheduleStatusEnum.cardPayment:
        return 'Card Payment'
      case PaymentScheduleStatusEnum.indemnityClaim:
        return 'Indemnity Claim'
      case PaymentScheduleStatusEnum.contactProvider:
      default:
        return 'Contact Provider'
    }
  }

  coverType<T extends SchemeQuoteResult>(quote: QuoteDetails<T>, selectedSchemeQuoteResult: number): string {
    if (quote instanceof CarQuoteDetails)
      return (quote as CarQuoteDetails).coverType.name

    if (quote instanceof GapQuoteDetails || quote instanceof TouringCaravanQuoteDetails || quote instanceof VehicleQuoteDetails || quote instanceof PetQuoteDetails || quote instanceof CaravanQuoteDetails || quote instanceof PropertyQuoteDetails)
      return quote.results.find(r => r.id === selectedSchemeQuoteResult)!.schemeName

    throw new Error(`Utils unable to use process type (${typeof quote})`)
  }

  getRenewalDate<T extends SchemeQuoteResult>(quote: QuoteDetails<T>, _selectedSchemQuoteResult: SchemeQuoteResult | null): Date {
    return moment(quote.effectiveDate)
      .add(1, 'year')
      .add(-1, 'day')
      .hours(23)
      .minutes(59)
      .seconds(59)
      .toDate()
  }

  checkConsent(cookiePolicyNotice: string | null): void {
    const cookieStore = useCookieStore()
    if (!cookieStore.consented && cookiePolicyNotice) {
      SnackbarProgrammatic.open({
        indefinite: true,
        message: cookiePolicyNotice,
        actionText: 'Got it',
        onAction: () => cookieStore.consent(),
        position: 'is-bottom-left',
      })
    }
  }

  findInvalidPageIndex(pages: PageAnswers[], answers: IQuestionAnswer[]): number {
    return pages.findIndex(page => page.answers.map(answer => ({
      id: answer.id,
      name: answer.name,
      answers: answer.subPageAnswers,
    })).some((answer) => {
      if (answer.name.toLowerCase() === QuestionEnum.pets.toLowerCase() || answer.name.toLowerCase() === QuestionEnum.additionalDriver.toLowerCase()) {
        const mappedAnswers = answers.map(a => ({
          id: a.id,
          name: a.name,
        }))
        const mappedFailedAnswers = answer.answers.flatMap(a => a.answers)
        return mappedFailedAnswers.findIndex(a => mappedAnswers.find(m => m.id === a.id)) > -1
      }
      return answers.findIndex(a => a.id === answer.id) > -1
    }))
  }

  findFailedAnswers(answers: IQuestionAnswer[], response: Dictionary<string>): IQuestionAnswer[] {
    const failedAnswers: IQuestionAnswer[] = []
    Object.keys(response).forEach((key) => {
      const splitName = key.split('.')
      let subPageQuestion: IQuestionAnswer | null = null

      if (key.toLowerCase().includes(QuestionEnum.pets.toLowerCase()))
        subPageQuestion = answers.find(a => a.name.toLowerCase() === QuestionEnum.pets.toLowerCase()) as QuestionAnswer<number>
      else if (key.toLowerCase().includes(QuestionEnum.additionalDriver.toLowerCase()))
        subPageQuestion = answers.find(a => a.name.toLowerCase() === QuestionEnum.additionalDriver.toLowerCase()) as QuestionAnswer<number>

      let answer: IQuestionAnswer
      if (subPageQuestion) {
        const index = (Number.parseInt(key.replace(/\D/g, ''), 10) || 0)
        const subPageAnswers = subPageQuestion.subPageAnswers[index].answers
        answer = subPageAnswers.find(a => a.name.toLowerCase() === splitName[splitName.length - 1].toLowerCase())!
      }
      else {
        answer = answers.find(a => a.name.toLowerCase() === splitName[splitName.length - 1].toLowerCase())!
      }

      if (answer) {
        answer.validationMessage = response[key]
        failedAnswers.push(answer)
      }
    })

    return failedAnswers
  }

  applyValidations(questions: IQuestion[], response: Dictionary<string>, validationObserver: any): void {
    Object.keys(response).forEach((key) => {
      const splitName = key.split('.')
      let subPageQuestion: SubPagesQuestion | null = null
      if (key.toLowerCase().includes(QuestionEnum.additionalDriver.toLowerCase()))
        subPageQuestion = questions.find(q => q.name.toLowerCase() === QuestionEnum.additionalDriver.toLowerCase()) as SubPagesQuestion
      else if (key.toLowerCase().includes(QuestionEnum.pets.toLowerCase()))
        subPageQuestion = questions.find(q => q.name.toLowerCase() === QuestionEnum.pets.toLowerCase()) as SubPagesQuestion
      else if (key.toLowerCase().includes(QuestionEnum.additionalVehicle.toLowerCase()))
        subPageQuestion = questions.find(q => q.name.toLowerCase() === QuestionEnum.additionalVehicle.toLowerCase()) as SubPagesQuestion
      else if (key.toLowerCase().includes(QuestionEnum.additionalPolicyholders.toLowerCase()))
        subPageQuestion = questions.find(q => q.name.toLowerCase() === QuestionEnum.additionalPolicyholders.toLowerCase()) as SubPagesQuestion

      let question: IQuestion
      let index = 0
      if (subPageQuestion) {
        index = (Number.parseInt(key.replace(/\D/g, ''), 10) || 0)
        const adDetailQuestions = subPageQuestion.subPageDetails[index].page.sections.flatMap(s => s.questions)
        question = adDetailQuestions.find(q => q.name.toLowerCase() === splitName[splitName.length - 1].toLowerCase())!
      }
      else {
        question = questions.find(q => q.name.toLowerCase() === splitName[splitName.length - 1].toLowerCase())!
      }

      if (question) {
        let providerName = `vp-${question.id}`
        if (index)
          providerName += `[${index}]`

        validationObserver.setErrors({ [providerName]: response[key] })
      }
    })
  }

  openCollapsibles(component: Vue): void {
    const refs = component.$refs.validationObserver
    if (!refs)
      return

    if (Array.isArray(refs)) {
      refs.forEach((ref) => {
        this.openCollapse(ref)
      })
    }
    else {
      this.openCollapse(refs)
    }
  }

  private openCollapse(observer: InstanceType<typeof ValidationObserver>) {
    const { collapse } = observer.$refs
    if (collapse as unknown) {
      if (collapse.isOpen)
        collapse.toggle()

      if (observer.flags.invalid)
        collapse.toggle()
    }
  }

  getPageTitle(defaultTitle: string, configuration: IConfiguration, product: ProductEnum): string {
    if (this.useAlternateBranding(product)) {
      const companyName = configuration.companyNameForProduct(product) ?? ''
      return defaultTitle.replaceAll(configuration.tenantFriendlyName.trim(), companyName)
    }
    return defaultTitle
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  handleBrandSwitching(product: ProductEnum | null) {
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  useAlternateBranding(product: ProductEnum | null): boolean {
    return false
  }

  getTenantConfigName(product: ProductEnum | null, configuration: IConfiguration): string {
    return product && this.useAlternateBranding(product)
      ? configuration.companyNameForProduct(product) ?? configuration.tenantConfigName
      : configuration.tenantConfigName
  }

  getTenantFriendlyName(product: ProductEnum | null, configuration: IConfiguration): string {
    return product && this.useAlternateBranding(product)
      ? configuration.companyNameForProduct(product) ?? configuration.tenantFriendlyName
      : configuration.tenantFriendlyName
  }

  getPaymentIcon(paymentIcon: string): string {
    return `/assets/img/common/${paymentIcon}.png`
  }

  specialMtaPreviousClickAction(_from: Route, _to: Routes): PageNavigationConfig | null {
    return {}
  }
}
