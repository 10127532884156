import type IAnalyticsConfig from '../IAnalyticsConfig'
import Analytics from './Analytics'
import { loadGoogleAnalytics, loadGoogleTagManager } from '@/composables/analytics-utils'

export default class QuotaxAnalytics extends Analytics {
  loadAnalytics(config: IAnalyticsConfig): void {
    // Google Tag Manager
    loadGoogleTagManager(window.injectedEnv.FRONTEND_GTM)

    // Google Analytics
    loadGoogleAnalytics(config)
  }
}
