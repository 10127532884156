import type _Vue from 'vue'
import type { Route } from 'vue-router'
import type IAnalyticsConfig from '../IAnalyticsConfig'
import type IAnalyticsWindow from '../IAnalyticsWindow'
import type IAnalytics from './IAnalytics'
import type AggregatorClient from '@/services/models/AggregatorClient'
import type Account from '@/services/models/Account'
import type QuoteDetails from '@/services/models/QuoteDetails'
import type Risk from '@/services/models/Risk'
import type SchemeQuoteResult from '@/services/models/SchemeQuoteResult'
import { loadHotjar } from '@/composables/analytics-utils'

export default class Analytics implements IAnalytics {
  public window: IAnalyticsWindow
  constructor(Vue: _Vue, config: IAnalyticsConfig, window: IAnalyticsWindow) {
    this.loadAnalytics(config)
    this.window = window

    config.router.afterEach((to, from) => {
      this.trackRouteChange(Vue, to, from)
    })
  }

  loadAnalytics(_config: IAnalyticsConfig | null): void {
    // Hotjar Tracking
    loadHotjar(window.injectedEnv.FRONTEND_HOTJAR)
  }

  async trackRouteChange(_vue: Vue, _to: Route, _from: Route): Promise<void> {
    // do nothing
  }

  trackNBSale<T extends SchemeQuoteResult>(vue: Vue, policyNumber: string, quote: QuoteDetails<T>, selectedSchemeQuoteResult: number, aggregatorClient: AggregatorClient | null, _risk: Risk | null): void {
    const result = quote.results.find(r => r.id === selectedSchemeQuoteResult)!

    // Google Tag Manager
    this.window.dataLayer?.push({
      policyNumber,
      schemeName: result.schemeName,
      premium: result.calculatedTotalPayable.toString(10),
      additional: result.additional.toString(10),
      aggregator: aggregatorClient?.name ?? '',
      event: 'nb-sale',
    })

    // Direct to Google Analytics sale tracking
    vue.$gtag?.purchase({

      transaction_id: policyNumber,
      affiliation: 'Ignite CF',
      value: result.calculatedTotalPayable,
      items: [
        {
          id: result.scheme.code,
          name: result.schemeName,
        },
      ],
    })
  }

  identifyUser(account: Account, window: IAnalyticsWindow): void {
    if (window.posthog && account?.id)
      window.posthog.identify(account.id)
  }
}
