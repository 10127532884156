import type IAnalyticsConfig from '../IAnalyticsConfig'
import Analytics from './Analytics'
import { loadGoogleAnalytics, loadGoogleTagManager, loadHotjar, loadMicrosoftAdvertising } from '@/composables/analytics-utils'

export default class ZoomcoverAnalytics extends Analytics {
  loadAnalytics(config: IAnalyticsConfig): void {
    // Google Tag Manager
    loadGoogleTagManager(window.injectedEnv.FRONTEND_GTM)

    // Google Analytics
    loadGoogleAnalytics(config)

    // Hotjar Tracking
    loadHotjar(window.injectedEnv.FRONTEND_HOTJAR)

    // Microsoft Advertising
    loadMicrosoftAdvertising(window.injectedEnv.FRONTEND_MICROSOFT_ADVERTISING)
  }
}
