enum UIFieldTypeEnum {
  ukDrivingLicence = 'drivingLicence',
  address = 'addressInput',
  vehicleLookup = 'vehicleInput',
  autocomplete = 'autocomplete',
  relatedAutoComplete = 'relatedAutoComplete',
  additionalPerson = 'additionalPerson',
  number = 'number',
  markdown = 'markdown',
  text = 'Text',
  email = 'email',
  tel = 'tel',
  date = 'Date',
  datetime = 'datetime',
  dateSelect = 'dateSelect',
  businessSource = 'Business Source',
  select = 'Defined List',
  multiSelect = 'multiselect',
  radio = 'radio',
  checkbox = 'Boolean',
  optInOptOut = 'optInOptOut',
  declarationCheckbox = 'declarationCheckbox',
  verisk = 'veriskInput',
  petComponent = 'PetComponent',
  storageLocationComponent = 'StorageLocationComponent',
  claims = 'claims',
  convictions = 'convictions',
  medicalconditions = 'MedicalConditions',
  modifications = 'Modifications',
  confirmEmail = 'confirmEmail',
  relatedSelectList = 'relatedSelectList',
  relatedCustomSelectList = 'relatedCustomSelectList',
  additionalVehicle = 'additionalVehicle',
  modal = 'Modal',
  coInsurance = 'coInsurance',
  textarea = 'TextArea',
  relatedRadioList = 'RelatedRadioList',
  specifiedItems = 'SpecifiedItems',
  multiSelectRadio = 'MultiSelectRadio',
  vehicleRegistrationInput = 'VehicleRegistrationInput',
}

export default UIFieldTypeEnum
